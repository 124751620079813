import React from 'react';
import { CreateLeadForm } from '@src/components/Forms';
import styles from './ContactUsCommunity.module.scss';
import { Column, Container, Row } from '@src/elements/Grid';
import Heading from '@src/elements/Heading';
import { StaticBlockData } from '@src/types/staticBlock';
import ContactCardList from '@src/components/ContactCardList';
import { ContentfulPageTypes } from '@src/types/contentful';
import { Location } from '@src/graphql/gql-types';

interface ContactUsCommunity {
  staticBlockData: StaticBlockData;
  contactPeopleCollection: Record<string, any>;
  pageType: ContentfulPageTypes;
  addressHeading?: string;
  address?: string;
  location?: Location;
}
const ContactUsCommunity: React.FC<ContactUsCommunity> = ({
  staticBlockData,
  contactPeopleCollection,
  pageType,
  addressHeading,
  address,
  location
}) => {
  const {
    communityContactForm: { disclaimerRichText, communityDetailsSegment } = {}
  } = staticBlockData;

  return (
    <Container>
      <Row>
        <Column xs={4} sm={4} md={8} lg={8} xl={6}>
          {/* contactScrollAnchor is targeted DOM node to scroll to from EnquireButton */}
          <div
            className={styles.contactUsCommunityScrollAnchor}
            id="contactScrollAnchor"
          />
          <div className={styles.contactUsCommunityHeading}>
            <Heading priority={3} fontStyle={'h3'}>
              Enquire about {communityDetailsSegment?.title} today
            </Heading>
          </div>

          <CreateLeadForm
            disclaimerRichText={disclaimerRichText}
            formType="community_contact"
          />
        </Column>
        <Column xs={4} sm={4} md={8} lg={8} xl={6}>
          <ContactCardList
            contactPeopleCollection={contactPeopleCollection}
            pageType={pageType}
            addressHeading={addressHeading}
            address={address}
            location={location}
          />
        </Column>
      </Row>
    </Container>
  );
};

export default ContactUsCommunity;
