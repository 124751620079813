import { gql, TypedDocumentNode } from '@apollo/client';
import {
  contentfulMetadataFragment,
  pageCommunityDetailHyperlinkFragment,
  pageCommunityDetailMetadataFragment,
  sysFragment
} from '@src/graphql/fragments';

export const pageCommunityDetailPathsQuery = gql`
  query pageCommunityDetailPaths {
    pageCommunityDetailCollection(
      where: { slug_not_in: ["/", "sitemap"] }
      limit: 100
    ) {
      items {
        ...pageCommunityDetailHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;

/**
 * Get a URL for a specific slug
 */
export const pageCommunityDetailPathQuery = gql`
  query pageCommunityDetailPath($slug: String, $id: String, $preview: Boolean) {
    pagePath: pageCommunityDetailCollection(
      where: { OR: [{ slug: $slug }, { sys: { id: $id } }] }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageCommunityDetailHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;
