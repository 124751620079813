import { gql } from '@apollo/client';

export const globalSettingsMetadataFragment = gql`
  fragment globalSettingsMetadata on GlobalSettings {
    __typename
    sys {
      ...sys
    }
  }
`;

export const globalSettingItemFragment = gql`
  fragment globalSettingItem on GlobalSettings {
    ...globalSettingsMetadata
    value
    richText {
      ...globalSettingsRT
    }
    active
    callToActionCollection(limit: 3) {
      items {
        ...cta
      }
    }
    location {
      lat
      lon
    }
    codeId
  }

  fragment globalSettingsRT on GlobalSettingsRichText {
    json
    links {
      ...globalSettingsRTLinks
    }
  }

  fragment globalSettingsRTLinks on GlobalSettingsRichTextLinks {
    entries {
      block {
        sys {
          ...sys
        }
      }
      inline {
        sys {
          ...sys
        }
        ...mediaAsset
      }
      hyperlink {
        ...hyperlink
        ...mediaAsset
      }
    }
  }
`;
