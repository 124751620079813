import React from 'react';
import { Themes } from '@src/types/themes';
import styles from './Pagination.module.scss';
import classNames from 'classnames';
import usePagination, { SpecialItemType } from '@src/hooks/usePagination';
import EllipsisSVG from '@src/svgs/EllipsisSVG/EllipsisSVG';
import { ArrowLeftSVG, ArrowRightSVG } from '@src/svgs';

interface Pagination {
  numPages: number;
  defaultPage?: number;
  siblingCount?: number;
  boundaryCount?: number;
  onPageChanged: (newPage: number) => void;
  theme?: Themes;
}

const renderSpecialPaginationItem = (type: SpecialItemType) => {
  if (
    type === 'ellipsis' ||
    type === 'end-ellipsis' ||
    type === 'start-ellipsis'
  ) {
    return <EllipsisSVG />;
  }
  if (type === 'previous') {
    return <ArrowLeftSVG color="slate" />;
  }
  if (type === 'next') {
    return <ArrowRightSVG color="slate" />;
  }
  if (type === 'first') {
    return 'first';
  }
  if (type === 'last') {
    return 'last';
  }
};

// make the page numbers 1-based as opposed to 0, since it makes more sense to keep consistent with the visual page numbers.
// Note that consumers will work with 0 based numbers when paginating the data, so we cater for this here.
const FIRST_PAGE_NUMBER = 1;

const Pagination: React.FC<Pagination> = ({
  theme = 'defaultTheme',
  numPages = 0,
  siblingCount = 1,
  boundaryCount = 2,
  defaultPage = 1,
  onPageChanged = () => {
    console.log('page changed');
  }
}) => {
  const { items, currentPage } = usePagination({
    count: numPages,
    siblingCount,
    boundaryCount,
    defaultPage,
    onChange: (_, value) => {
      onPageChanged((value || defaultPage) - FIRST_PAGE_NUMBER);
    }
  });

  return (
    <div
      data-testid="pagination"
      className={`theme-${theme} ${styles.container}`}
    >
      {items?.map((item) => (
        <div
          className={classNames(styles.pageButton, {
            [styles.pageButtonSelected]: currentPage === item.page,
            [styles.pageButtonSpecial]: item.type !== 'page'
          })}
          key={`${item.type}-${item.page}`}
          onClick={item.onClick}
        >
          {item.type === 'page'
            ? item.page
            : renderSpecialPaginationItem(item.type as SpecialItemType)}
        </div>
      ))}
    </div>
  );
};

export default Pagination;
