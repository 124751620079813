import { gql } from '@apollo/client';
/**
 * For a rich text field, to get the links to all pages, add the following to hyperlink
 *
 *  hyperlink {
      ...pageNationalStandardHyperlink
      ...pageNationalNewsHyperlink
      ...pageCommunityDetailHyperlink
      ...pageCommunityStandardHyperlink
      ...pageCommunityNewsHyperlink
      ...pageCommunityEventHyperlink
      ...pageCommunityPropertyDetailHyperlink
      ...mediaAsset
    }
 */

export const hyperlinkFragment = gql`
  fragment hyperlink on Entry {
    sys {
      ...sys
    }
    ...pageNationalStandardHyperlink
    ...pageNationalNewsHyperlink
    ...pageCommunityDetailHyperlink
    ...pageCommunityStandardHyperlink
    ...pageCommunityNewsHyperlink
    ...pageCommunityEventHyperlink
    ...pageCommunityPropertyDetailHyperlink
    ...mediaAsset
  }
`;

// =================== NATIONAL ===================================================
/**
 * __This tries for 4 levels deep__ , all of type National Standard
 */
export const pageNationalStandardHyperlinkFragment = gql`
  fragment pageNationalStandardHyperlink on Entry {
    ... on PageNationalStandard {
      ...pageNationalStandardMetadata
      parentPage {
        ...pageNationalStandardMetadata
        parentPage {
          ...pageNationalStandardMetadata
          parentPage {
            ...pageNationalStandardMetadata
          }
        }
      }
    }
  }
`;

/**
 * __National News only has a hardcoded path of '/news/'__
 *
 * No parent looks up needed.
 */
export const pageNationalNewsHyperlinkFragment = gql`
  fragment pageNationalNewsHyperlink on Entry {
    ... on PageNationalNews {
      ...pageNationalNewsMetadata
    }
  }
`;

// =================== COMMUNITY ==================================================
/**
 * Get Community detail page __(it has no parent, only a hardcoded parent of '/community/')__
 *
 * No parent looks up needed.
 */
export const pageCommunityDetailHyperlinkFragment = gql`
  fragment pageCommunityDetailHyperlink on Entry {
    ... on PageCommunityDetail {
      ...pageCommunityDetailMetadata
    }
  }
`;

/**
 * Get all the possible community pages and their parents.
 *
 * __This goes 4 levels deep and should include `community` parent__
 */
export const pageCommunityStandardHyperlinkFragment = gql`
  fragment pageCommunityStandardHyperlink on Entry {
    ... on PageCommunityStandard {
      ...pageCommunityStandardMetadata
      parentPage {
        ... on PageCommunityStandard {
          ...pageCommunityStandardMetadata
          parentPage {
            ... on PageCommunityStandard {
              ...pageCommunityStandardMetadata
              parentPage {
                ... on PageCommunityStandard {
                  ...pageCommunityStandardMetadata
                  parentPage {
                    ... on PageCommunityStandard {
                      ...pageCommunityStandardMetadata
                    }
                    ... on PageCommunityDetail {
                      ...pageCommunityDetailMetadata
                    }
                  }
                }
                ... on PageCommunityDetail {
                  ...pageCommunityDetailMetadata
                }
              }
            }
            ... on PageCommunityDetail {
              ...pageCommunityDetailMetadata
            }
          }
        }
        ... on PageCommunityDetail {
          ...pageCommunityDetailMetadata
        }
      }
    }
  }
`;

/**
 * __Get all community news pages and their `community` parent.__
 */
export const pageCommunityNewsHyperlinkFragment = gql`
  fragment pageCommunityNewsHyperlink on Entry {
    ... on PageCommunityNews {
      ...pageCommunityNewsMetadata
      parentPage: community {
        ...pageCommunityDetailMetadata
      }
    }
  }
`;

/**
 * __Get all community events pages and their `community` parent.__
 */
export const pageCommunityEventHyperlinkFragment = gql`
  fragment pageCommunityEventHyperlink on Entry {
    ... on PageCommunityEvent {
      ...pageCommunityEventMetadata
      parentPage: community {
        ...pageCommunityDetailMetadata
      }
    }
  }
`;

/**
 * __Get all shared news articles `community` parent.__
 */
export const pageSharedNewsHyperlinkFragment = gql`
  fragment pageSharedNewsHyperlink on Entry {
    ... on PageSharedNews {
      ...pageSharedNewsMetadata
      sourcePage {
        ...pageNationalNewsMetadata
      }
      parentPage: community {
        ...pageCommunityDetailMetadata
      }
    }
  }
`;

/**
 * __Get all community property detail pages and their `community` parent.__
 */
export const pageCommunityPropertyDetailHyperlinkFragment = gql`
  fragment pageCommunityPropertyDetailHyperlink on Entry {
    ... on PageCommunityPropertyDetail {
      ...pageCommunityPropertyDetailMetadata
      parentPage: community {
        ...pageCommunityDetailMetadata
      }
    }
  }
`;

/**
 * FROM A PRODUCT-CATALOG
 *
 *  __Get all community property detail pages and its `community` parent.__
 *
 */
export const productsHyperlinkFragment = gql`
  fragment productsHyperlink on Entry {
    ... on ProductCatalog {
      __typename
      linkedFrom {
        pageCommunityPropertyDetail: pageCommunityPropertyDetailCollection(
          limit: 10
        ) {
          items {
            ...pageCommunityPropertyDetailHyperlink
          }
        }
      }
    }
    ... on Product {
      __typename
      linkedFrom {
        pageCommunityPropertyDetail: pageCommunityPropertyDetailCollection(
          limit: 10
        ) {
          items {
            ...pageCommunityPropertyDetailHyperlink
          }
        }
      }
    }
  }
`;
