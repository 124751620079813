import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import { Column, Container, Row } from '@src/elements/Grid';
import Heading from '@src/elements/Heading';
import {
  CtaFragment,
  GlobalSettingItemFragment,
  PageCommunityDetailFragment,
  SalesBannerBlockFragment
} from '@src/graphql/gql-types';
import { COLOR_SLATE } from '@src/types/colors';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import { getEnquiryFormValues } from '@src/utils/form';
import { getArrayOfCTAs } from '@src/utils/helpers';
import React from 'react';
import RichTextRender from '@src/components/RichTextRender';
import styles from './SalesBannerBlock.module.scss';
import CTAButton from '@src/components/Button/CTAButton';
import { ButtonStandard } from '@src/components/Button';
import { NationalEnquiryDynamicFormValues } from '@src/types/forms';
import { CreateLeadForm } from '@src/components/Forms';
import useModalStore from '@src/store';

type SalesBannerBlock = SalesBannerBlockFragment & {
  communities?: PageCommunityDetailFragment[];
  formDisclaimerRichText?: GlobalSettingItemFragment;
};

const SalesBannerBlock: React.FC<SalesBannerBlock> = ({
  heading,
  bodyContent,
  callToActionCollection,
  disclaimerText,
  communities,
  formDisclaimerRichText
}) => {
  const { openModal } = useModalStore();
  const ctasArray = getArrayOfCTAs(
    callToActionCollection?.items as CtaFragment[]
  );

  const dynamicFormValues = getEnquiryFormValues(
    communities as PageCommunityDetailFragment[]
  );

  const handleEnquireButtonClick = () => {
    const modalData = {
      heading: 'Enquire now',
      content: (
        <CreateLeadForm
          disclaimerRichText={formDisclaimerRichText}
          formType="national_enquiry"
          nationalEnquiryDynamicFormValues={
            dynamicFormValues as NationalEnquiryDynamicFormValues
          }
        />
      )
    };

    openModal(modalData);
  };

  const renderCTAButton = (cta: CtaFragment, index: number) => {
    return cta.modalId ? (
      <ButtonStandard
        key={`${cta.label}_${index}`}
        color={COLOR_SLATE}
        aria-label={cta.label || ''}
        onClick={handleEnquireButtonClick}
      >
        {cta.label}
      </ButtonStandard>
    ) : (
      <CTAButton
        key={`${cta.label}_${index}`}
        cta={cta}
        color={COLOR_SLATE}
        label={cta.label || ''}
      />
    );
  };

  return (
    <div className={styles.salesBannerBlock}>
      <Container>
        <Row>
          <Column xs={4} md={3} xl={4}>
            <Heading priority={2} fontStyle="h2">
              {heading}
            </Heading>
          </Column>

          <Column xs={4} md={5} xl={8}>
            {bodyContent && (
              <div className={styles.copy}>
                <RichTextRender
                  bodyContent={bodyContent}
                  pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD} // Forcing this type at the moment, but it might not be needed/or applied in this instance
                  renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                  slateLinkStyle
                />
              </div>
            )}

            <div className={styles.cta}>{ctasArray?.map(renderCTAButton)}</div>

            <div className={styles.disclaimer}>
              {disclaimerText && (
                <RichTextRender
                  bodyContent={disclaimerText}
                  pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD} // Forcing this type at the moment, but it might not be needed/or applied in this instance
                  renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                  slateLinkStyle
                />
              )}
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default SalesBannerBlock;
