import { gql } from '@apollo/client';

export const heroSmallFragment = gql`
  fragment heroSmall on HeroSmall {
    __typename
    sys {
      publishedAt
      ...sys
    }
    heading
    description
    mediaItem {
      ...mediaImage
    }
    plain
  }
`;

export const heroCardImageFragment = gql`
  fragment heroCardImage on HeroSmall {
    mediaItem {
      sys {
        ...sys
      }
      cloudinarySource
    }
  }
`;
