import { gql } from '@apollo/client';

export const newsListingCardNationalFragment = gql`
  fragment newsListingCardNational on PageNationalNews {
    title
    publishDate
    hero {
      ...heroCardImage
    }
    readingTime
    ...pageNationalNewsMetadata
    ...pageCommunityNewsHyperlink
  }
`;

export const newsListingCardCommunityFragment = gql`
  fragment newsListingCardCommunity on PageCommunityNews {
    ...pageCommunityNewsMetadata
    publishDate
    hero {
      ...heroCardImage
    }
    readingTime
    ...pageCommunityNewsHyperlink
  }
`;

export const eventListingCardCommunityFragment = gql`
  fragment eventListingCardCommunity on PageCommunityEvent {
    ...pageCommunityEventMetadata
    hero {
      ...heroCardImage
    }
    description {
      json
    }
    displayAddress
    eventDate
    eventEndDate
    startTime
    endTime
    parentPage: community {
      ...pageCommunityDetailMetadata
    }
  }
`;
