import { gql, TypedDocumentNode } from '@apollo/client';

import {
  sysFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityStandardMetadataFragment,
  pageCommunityDetailMetadataFragment,
  mediaImageFragment,
  mediaAssetFragment,
  CTAFragment,
  genericTextBlockFragment,
  hyperlinkFragment,
  pageCommunityDetailHyperlinkFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityNewsMetadataFragment,
  pageCommunityEventHyperlinkFragment,
  pageCommunityEventMetadataFragment,
  pageNationalNewsHyperlinkFragment,
  pageNationalNewsMetadataFragment,
  pageNationalStandardHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  sectionTitleBlockFragment,
  productPromotionBlockFragment,
  mediaVideoFragment,
  alternatingGalleryBlockFragment,
  alternatingTextBlockFragment,
  contentfulMetadataFragment,
  tileContainerBlockFragment,
  galleryFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityPropertyDetailMetadataFragment,
  heroSmallFragment,
  videoBlockFragment,
  matterportBlockFragment,
  twoColumnBlockFragment,
  contentBlocksPart1CollectionCommunityStandardFragment,
  contentBlocksPart2CollectionCommunityStandardFragment,
  communityContactUsFragment,
  accordionBlockFragment,
  discoverBlockFragment,
  staticBlockFragment,
  contactPeopleFragment
} from '@src/graphql/fragments';

/**
 * This query uses $id as `pageCommunityStandard` slugs are not unique unique for this content-type
 * Example WHERE OR statement { OR: [{ slug: $slug }, { sys: { id: $id } }] }
 */
export const pageCommunityStandardQuery = gql`
  query pageCommunityStandard($id: String, $preview: Boolean) {
    page: pageCommunityStandardCollection(
      where: { sys: { id: $id } }
      preview: $preview
      limit: 1
    ) {
      items {
        sys {
          publishedAt
          ...sys
        }
        title
        slug
        seoTitle
        seoDescription
        noIndex
        noFollow
        hero {
          ...heroSmall
        }
        introText
        discoverBlock {
          ...discoverBlock
        }
        displayGlobalCtaPanel
        disclaimer {
          ...genericTextBlock
        }
      }
    }

    pagePath: pageCommunityStandardCollection(
      where: { sys: { id: $id } }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageCommunityStandardHyperlink
      }
    }
  }

  ${sysFragment}

  ${contentfulMetadataFragment}
  ${genericTextBlockFragment}
  ${discoverBlockFragment}

  ${mediaAssetFragment}
  ${mediaImageFragment}
  ${mediaVideoFragment}
  ${galleryFragment}

  ${heroSmallFragment}

  ${hyperlinkFragment}
  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;

export const pageCommunityStandardPart1ContentBlocksQuery = gql`
  query pageCommunityStandardPart1ContentBlocks(
    $id: String
    $preview: Boolean
  ) {
    pageContentBlocks: pageCommunityStandardCollection(
      where: { sys: { id: $id } }
      preview: $preview
      limit: 1
    ) {
      items {
        ...contentBlocksPart1CollectionCommunityStandard
      }
    }
  }

  ${sysFragment}
  ${hyperlinkFragment}
  ${contentfulMetadataFragment}

  ${galleryFragment}
  ${CTAFragment}

  ${contentBlocksPart1CollectionCommunityStandardFragment}

  ${mediaAssetFragment}
  ${mediaImageFragment}
  ${mediaVideoFragment}

  ${alternatingGalleryBlockFragment}
  ${alternatingTextBlockFragment}
  ${communityContactUsFragment}
  ${genericTextBlockFragment}
  ${matterportBlockFragment}
  ${productPromotionBlockFragment}
  ${sectionTitleBlockFragment}
  ${staticBlockFragment}
  ${tileContainerBlockFragment}
  ${contactPeopleFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;

export const pageCommunityStandardPart2ContentBlocksQuery = gql`
  query pageCommunityStandardPart2ContentBlocks(
    $id: String
    $preview: Boolean
  ) {
    pageContentBlocks: pageCommunityStandardCollection(
      where: { sys: { id: $id } }
      preview: $preview
      limit: 1
    ) {
      items {
        ...contentBlocksPart2CollectionCommunityStandard
      }
    }
  }

  ${sysFragment}
  ${hyperlinkFragment}
  ${contentfulMetadataFragment}

  ${CTAFragment}

  ${contentBlocksPart2CollectionCommunityStandardFragment}

  ${mediaAssetFragment}
  ${mediaVideoFragment}

  ${accordionBlockFragment}
  ${twoColumnBlockFragment}
  ${videoBlockFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;
