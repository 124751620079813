import { gql } from '@apollo/client';

export const navFragment = gql`
  fragment nav on Navigation {
    navigationElementsCollection(limit: 8) {
      items {
        ...navSubEl
      }
    }
  }
`;

// Pete: Note, was getting query complication issue here, when we go to the 3rd level, which we aren't displaying on the front end
// so have removed this, and query now works without issues.
export const navSubElFragment = gql`
  fragment navSubEl on NavigationElement {
    ...navEl
    subNavItems: navigationElementCollection(limit: 10) {
      items {
        ...navEl
      }
    }
  }
`;

export const navElFragment = gql`
  fragment navEl on NavigationElement {
    __typename
    sys {
      ...sys
    }
    title
    linkType
    internalPageLink {
      ...hyperlink
    }
    customUrl
    openLinkInNewWindow
  }
`;
