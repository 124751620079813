import React from 'react';
import styles from './VideoBlock.module.scss';
import { VideoBlockFragment } from '@src/graphql/gql-types';
import Heading from '@src/elements/Heading';
import { Container } from '@src/elements/Grid';
import classNames from 'classnames';
import MediaVideo from '@src/components/MediaVideo';

type VideoBlock = VideoBlockFragment;

const VideoBlock: React.FC<VideoBlock> = ({
  heading,
  description,
  mediaVideo,
  applyTheme
}) => {
  return (
    <div
      className={classNames(styles.videoBlock, {
        [styles.videoBlockThemed]: applyTheme
      })}
    >
      <Container>
        <div className={styles.content}>
          {(heading || description) && (
            <div className={styles.textContainer}>
              {heading && (
                <div className={styles.heading}>
                  <Heading priority={2} fontStyle="h2">
                    {heading}
                  </Heading>
                </div>
              )}
              {description && (
                <div className={styles.description}>
                  <Heading priority={4} fontStyle="h4">
                    {description}
                  </Heading>
                </div>
              )}
            </div>
          )}
          <div className={styles.videoBlockWrapper}>
            {mediaVideo && (
              <MediaVideo id={heading} {...mediaVideo} applyTheme />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default VideoBlock;
