import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './NumericStepperNonForm.module.scss';
import { SvgIconName } from '@src/types/svg';
import Icon from '@src/elements/Icon';
import { ButtonIcon } from '@src/components/Button';
import { COLOR_SLATE } from '@src/types/colors';

interface NumericStepperNonForm extends React.HTMLProps<HTMLInputElement> {
  defaultValue?: number;
  /**
   * make ID required
   */
  id: string;
  /**
   * The label for the input
   */
  label?: string;
  /**
   * Icon for the field label
   */
  labelIcon?: SvgIconName;
  /**
   * Min value for the stepper
   */
  min: number;
  /**
   * Max value for the stepper
   */
  max: number;

  onValueChanged?: (newValue: number) => void;
}

const NumericStepperNonForm: React.FC<NumericStepperNonForm> = ({
  id,
  label,
  labelIcon,
  min,
  max,
  defaultValue = 1,
  onValueChanged = (value) => {
    console.debug(value);
  },
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);
  const [maxReached, setMaxReached] = useState(false);
  const [minReached, setMinReached] = useState(false);

  useEffect(() => {
    setMaxReached(value === max);
    setMinReached(value === min);
  }, [max, min, value]);

  // update field value and disabled state
  const updateValue = ({ increment }: { increment: boolean }) => {
    const currentValue = value;

    if (increment && currentValue < max) {
      setValue(currentValue + 1);
      setMaxReached(currentValue + 1 === max);
      setMinReached(false);
      onValueChanged(currentValue + 1);
    }

    if (!increment && currentValue > min) {
      setValue(currentValue - 1);
      setMinReached(currentValue - 1 === min);
      setMaxReached(false);
      onValueChanged(currentValue - 1);
    }
  };

  return (
    <div className={styles.numericStepperNonForm}>
      <label className={styles.label} htmlFor={id}>
        {labelIcon && <Icon icon={labelIcon} />}
        {label}
      </label>

      <div className={styles.numericStepperNonFormControls}>
        <div>
          <ButtonIcon
            icon="Minus"
            color={COLOR_SLATE}
            fillVariant="outline"
            onClick={() => updateValue({ increment: false })}
            aria-label="Increase"
          />
        </div>
        <input
          value={`${value}+`}
          id={id}
          type="text"
          readOnly
          className={classNames(styles.input, {
            [styles.inputReadOnly]: rest.readOnly
          })}
          disabled={minReached || maxReached}
          {...rest}
        />
        <div>
          <ButtonIcon
            icon="Plus"
            color={COLOR_SLATE}
            fillVariant="outline"
            onClick={() => updateValue({ increment: true })}
            aria-label="Descrease"
          />
        </div>
      </div>
    </div>
  );
};

export default NumericStepperNonForm;
