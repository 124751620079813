import { PATHS } from '@src/constants/site';
import { mapCommunityDetail } from '@src/graphql/queries/pageCommunityDetail.query';
import { apolloClient } from '@src/services/apollo';
import { getLastSegmentFromURL, isInBrowserURL } from '@src/utils/url';
import { NextRouter } from 'next/router';

export const getMapData = async (router: NextRouter) => {
  // TODO: can we use an ID or different identifier rather than hardcoding?
  const stateSlug = isInBrowserURL(PATHS.FIND_YOUR_COMMUNITY)
    ? getLastSegmentFromURL(router)
    : '';

  if (stateSlug) {
    const mapQueryResponse = await apolloClient.query({
      query: mapCommunityDetail,
      variables: { stateSlug }
    });
    if (
      mapQueryResponse.data?.stateCollection?.items?.find(
        (s: any) => s.slug === stateSlug
      )
    ) {
      return mapQueryResponse.data?.mapItems?.items;
    }
  }

  return;
};
