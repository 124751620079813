import React from 'react';
import styles from './TwoColumnBlock.module.scss';
import { TwoColumnBlockFragment } from '@src/graphql/gql-types';
import Heading from '@src/elements/Heading';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import RichTextRender from '@src/components/RichTextRender';
import { COLOR_PRIMARY } from '@src/types/colors';
import { Container } from '@src/elements/Grid';
import classNames from 'classnames';
import { ContentBlocksData } from '@src/types/contentBlocks';
import { replaceStringWithDictionary } from '@src/utils/strings';
import CTAButton from '@src/components/Button/CTAButton';

type TwoColumnBlock = TwoColumnBlockFragment & {
  sharedBuildData?: ContentBlocksData;
};

const TwoColumnBlock: React.FC<TwoColumnBlock> = ({
  heading,
  description,
  applyTheme,
  leftColumnHeading,
  leftColumnBodyContent,
  leftColumnPrimaryCallToAction,
  rightColumnHeading,
  rightColumnBodyContent,
  rightColumnPrimaryCallToAction,
  sharedBuildData
}) => {
  const replacementDictionary = sharedBuildData?.replacementDictionary;

  const descriptionText = replacementDictionary
    ? replaceStringWithDictionary(description, replacementDictionary)
    : description;

  return (
    <div
      className={classNames(styles.twoColumnBlock, {
        [styles.themed]: applyTheme
      })}
    >
      <Container>
        <div className={styles.header}>
          <Heading priority={2} fontStyle="h2">
            {replaceStringWithDictionary(heading, replacementDictionary)}
          </Heading>
          <div className={styles.description}>
            <Heading priority={3} fontStyle="h4">
              {descriptionText}
            </Heading>
          </div>
        </div>
        <div className={styles.columns}>
          <div className={styles.leftColumn}>
            {leftColumnHeading && (
              <div className={styles.columnHeading}>
                <Heading priority={4} fontStyle="h4">
                  {replaceStringWithDictionary(
                    leftColumnHeading,
                    replacementDictionary
                  )}
                </Heading>
              </div>
            )}
            {leftColumnBodyContent && (
              <RichTextRender
                bodyContent={leftColumnBodyContent}
                pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD} // Forcing this type at the moment, but it might not be needed/or applied in this instance
                renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                replacementDictionary={replacementDictionary}
                slateLinkStyle={!!applyTheme}
              />
            )}
            {leftColumnPrimaryCallToAction && (
              <div className={styles.ctaWrapper}>
                <CTAButton
                  cta={leftColumnPrimaryCallToAction}
                  color={COLOR_PRIMARY}
                  fillVariant="outline"
                  label={leftColumnPrimaryCallToAction.label || ''}
                />
              </div>
            )}
          </div>
          <div className={styles.divider} />
          <div className={styles.rightColumn}>
            {rightColumnHeading && (
              <div className={styles.columnHeading}>
                <Heading priority={4} fontStyle="h4">
                  {replaceStringWithDictionary(
                    rightColumnHeading,
                    replacementDictionary
                  )}
                </Heading>
              </div>
            )}
            {rightColumnBodyContent && (
              <RichTextRender
                bodyContent={rightColumnBodyContent}
                pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD} // Forcing this type at the moment, but it might not be needed/or applied in this instance
                renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                replacementDictionary={replacementDictionary}
                slateLinkStyle={!!applyTheme}
              />
            )}
            {rightColumnPrimaryCallToAction && (
              <div className={styles.ctaWrapper}>
                <CTAButton
                  cta={rightColumnPrimaryCallToAction}
                  color={COLOR_PRIMARY}
                  fillVariant="outline"
                  label={rightColumnPrimaryCallToAction.label || ''}
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TwoColumnBlock;
