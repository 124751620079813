import { gql, TypedDocumentNode } from '@apollo/client';
import {
  pageNationalNewsMetadataFragment,
  sysFragment
} from '@src/graphql/fragments';

export const pageNationalNewsPathsQuery = gql`
  query pageNationalNewsPaths {
    pageNationalNewsCollection(
      where: { slug_not_in: ["/", "sitemap"] }
      limit: 500
    ) {
      items {
        slug
      }
    }
  }
` as TypedDocumentNode;

/**
 * Get a URL for a specific slug
 */
export const pageNationalNewsPathQuery = gql`
  query pageNationalNewsPath($slug: String, $id: String, $preview: Boolean) {
    pagePath: pageNationalNewsCollection(
      where: { OR: [{ slug: $slug }, { sys: { id: $id } }] }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageNationalNewsMetadata
      }
    }
  }
  ${sysFragment}
  ${pageNationalNewsMetadataFragment}
` as TypedDocumentNode;
