import { gql } from '@apollo/client';

export const heroLargeFragment = gql`
  fragment heroLarge on HeroLarge {
    __typename
    sys {
      publishedAt
      ...sys
    }
    heading
    description
    mediaItem {
      ...mediaImage
      ...mediaVideo
    }
    primaryCta {
      label
      internalLink {
        ...hyperlink
      }
      urlLink
      assetLink {
        ...mediaAsset
      }
    }
  }
`;
