import React from 'react';
import { Column, Container, Row } from '@src/elements/Grid';
import styles from './ContactUsNational.module.scss';
import RichTextRender from '@src/components/RichTextRender';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import Content from '@src/elements/Content';
import Heading from '@src/elements/Heading';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import { ContentBlocksData } from '@src/types/contentBlocks';
import {
  GlobalSettingItemFragment,
  PageCommunityDetailFragment
} from '@src/graphql/gql-types';
import Map from '@src/components/Map';
import CreateCaseForm from '@src/components/Forms/CreateCaseForm';
import { NationalEnquiryDynamicFormValues } from '@src/types/forms';
import { getEnquiryFormValues } from '@src/utils/form';

interface ContactUsNational {
  disclaimerRichText?: GlobalSettingItemFragment;
  headOfficeContent?: GlobalSettingItemFragment;
  sharedBuildData: ContentBlocksData;
  communities?: PageCommunityDetailFragment[] | undefined;
}

const ContactUsNational: React.FC<ContactUsNational> = ({
  disclaimerRichText,
  headOfficeContent,
  sharedBuildData,
  communities
}) => {
  const communityItems = communities as PageCommunityDetailFragment[];
  const dynamicFormValues = getEnquiryFormValues(communityItems);

  return (
    <>
      <Container>
        {/* contactScrollAnchor is targeted DOM node to scroll to from EnquireButton */}
        <div
          className={styles.contactUsNationalScrollAnchor}
          id="contactScrollAnchor"
        />
        <Row>
          <Column
            xs={4}
            sm={4}
            md={6}
            lg={6}
            xl={6}
            offsetMd={1}
            offsetLg={1}
            offsetXl={3}
          >
            <CreateCaseForm
              disclaimerRichText={disclaimerRichText}
              nationalEnquiryDynamicFormValues={
                dynamicFormValues as NationalEnquiryDynamicFormValues
              }
            />
          </Column>
        </Row>
      </Container>
      {headOfficeContent && headOfficeContent?.richText && (
        <div className={styles.contactUsNationalHeadOffice}>
          <Container>
            <Content>
              <Heading priority={2} fontStyle={'h2'}>
                {headOfficeContent?.value}
              </Heading>
              <Row>
                <Column
                  xs={4}
                  sm={4}
                  md={6}
                  lg={6}
                  xl={8}
                  offsetMd={1}
                  offsetLg={1}
                  offsetXl={2}
                >
                  <RichTextRender
                    bodyContent={headOfficeContent?.richText}
                    pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD} // Forcing this type at the moment, but it might not be needed/or applied in this instance
                    renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                    replacementDictionary={
                      sharedBuildData?.replacementDictionary
                    }
                  />
                </Column>
              </Row>
            </Content>
            <div className={styles.contactUsNationalMapWrapper}>
              <Map
                zoom={17}
                zoomControl={false}
                fullscreenControl={false}
                streetViewControl={false}
                mapTypeControl={false}
                hasPopupCard={false}
                center={{
                  lat: Number(headOfficeContent.location?.lat),
                  lng: Number(headOfficeContent.location?.lon)
                }}
                mapData={[
                  {
                    title: 'Levande head office',
                    latLon: {
                      lat: headOfficeContent.location?.lat,
                      lon: headOfficeContent.location?.lon
                    }
                  }
                ]}
              />
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default ContactUsNational;
