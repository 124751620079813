import MediaImage from '@src/components/MediaImage';
import Pill from '@src/components/Pill';
import Heading from '@src/elements/Heading';
import {
  CtaFragment,
  MediaAssetFragment,
  MediaImageFragment
} from '@src/graphql/gql-types';
import {
  Colors,
  COLOR_BLACK,
  COLOR_BLUE,
  COLOR_ORANGE,
  COLOR_PRIMARY,
  COLOR_YELLOW
} from '@src/types/colors';
import classNames from 'classnames';
import React from 'react';
import styles from './ProductPromotionCard.module.scss';
import CTAButton from '@src/components/Button/CTAButton';

interface ProductPromotionCard {
  heading: string;
  description: string;
  image: MediaImageFragment;
  cta: CtaFragment;
  urlLink: string;
  assetLink: MediaAssetFragment;
  optionNumber: number;
  color?: Colors;
}

const ProductPromotionCard: React.FC<ProductPromotionCard> = ({
  heading,
  description,
  image,
  cta,
  optionNumber,
  color
}) => {
  return (
    <div className={styles.productPromotionCard}>
      <div className={styles.backgroundWrapper}>
        <div className={styles.backgroundMediaWrapper}>
          <MediaImage {...image} />
        </div>
        <div className={styles.background}>
          <div
            className={classNames(styles.backgroundBlock, {
              [styles.yellowBlock]: color === COLOR_YELLOW,
              [styles.orangeBlock]: color === COLOR_ORANGE,
              [styles.blueBlock]: color === COLOR_BLUE
            })}
          ></div>
          <svg
            className={classNames(styles.backgroundTriangle, {
              [styles.yellowTriangle]: color === COLOR_YELLOW,
              [styles.orangeTriangle]: color === COLOR_ORANGE,
              [styles.blueTriangle]: color === COLOR_BLUE
            })}
            viewBox="0 0 301 677"
          >
            <polygon points="301 0, 0 677, 301 677" />
          </svg>
        </div>
      </div>
      <div className={styles.headingWrapper}>
        <div>
          <div className={styles.option}>
            <Pill
              color={color || COLOR_PRIMARY}
              text={'Option ' + optionNumber}
            />
          </div>
          <Heading priority={4} fontStyle="h4">
            {heading}
          </Heading>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
        {cta && (
          <div className={styles.cta}>
            <CTAButton
              cta={cta}
              color={COLOR_BLACK}
              fillVariant="outline"
              label={cta.label || 'Find out more'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductPromotionCard;
