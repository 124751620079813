import { gql, TypedDocumentNode } from '@apollo/client';
import {
  contentfulMetadataFragment,
  pageCommunityDetailMetadataFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityPropertyDetailMetadataFragment,
  sysFragment
} from '@src/graphql/fragments';

export const pageCommunityPropertyDetailPathsQuery = gql`
  query pageCommunityPropertyDetailPaths {
    pageCommunityPropertyDetailCollection(
      where: { slug_not_in: ["/", "sitemap"] }
      limit: 500
    ) {
      items {
        ...pageCommunityPropertyDetailHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageCommunityPropertyDetailMetadataFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
  ${pageCommunityDetailMetadataFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;

/**
 * Get the page hyperlink URL/path for a specific page ID
 * This query uses $id as `pageCommunityPropertyDetail` slugs are not unique for this content-type
 */
export const pageCommunityPropertyDetailPathQuery = gql`
  query pageCommunityPropertyDetailPath(
    $id: String
    $nextJsParamsHashId: String
    $preview: Boolean
  ) {
    pagePath: pageCommunityPropertyDetailCollection(
      where: {
        OR: [{ sys: { id: $id } }, { nextJsParamsHashId: $nextJsParamsHashId }]
      }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageCommunityPropertyDetailHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageCommunityPropertyDetailMetadataFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
  ${pageCommunityDetailMetadataFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;
