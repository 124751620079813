import Dropdown from '@src/elements/Dropdown';
import NumericStepper from '@src/elements/NumericStepperNonForm';
import { Column, Container, Row } from '@src/elements/Grid';
import Icon from '@src/elements/Icon';
import { COLOR_PRIMARY, COLOR_SLATE } from '@src/types/colors';
import {
  SVG_BATH,
  SVG_BED,
  SVG_BUSINESS,
  SVG_CAR,
  SVG_CLOSE,
  SVG_DOLLAR_SIGN,
  SVG_FILTER
} from '@src/types/svg';
import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import { ButtonStandard } from '../Button';
import styles from './PropertyFilter.module.scss';
import { PageCommunityPropertyDetail } from '@src/graphql/gql-types';
import { pascalToDisplay } from '@src/utils/strings';

export type SortableField = keyof Pick<
  PageCommunityPropertyDetail,
  | 'maxPrice'
  | 'minPrice'
  | 'title'
  | 'noOfBaths'
  | 'noOfBeds'
  | 'noOfCars'
  | 'propertyType'
>;

export interface Filters {
  maxPriceLow?: number;
  maxPriceHigh?: number;
  numBeds?: number;
  numBaths?: number;
  numCarSpaces?: number;
  propertyTypes?: string[];
}

interface PropertyFilter {
  availablePropertyTypes?: string[];
  onFiltersUpdate: (filters: Filters) => void;
  onSortUpdate: (args: {
    fieldName: SortableField;
    order?: 'asc' | 'desc';
  }) => void;
}

enum SortOptionKey {
  PRICE_ASC = 'price-asc',
  PRICE_DESC = 'price-desc',
  CLEAR = 'clear'
}

enum SortOptionLabel {
  PRICE_ASC = 'Price - Low to high',
  PRICE_DESC = 'Price - High to low'
}

interface SortOption {
  key: SortOptionKey;
  label: string;
  id: SortOptionKey;
}

// TODO - these are arbitrary values for the moment, should update when requirements are clearer
const FILTER_PRICE_LOW = 50000;
const FILTER_PRICE_HIGH = 5000000;

const SORT_OPTIONS: SortOption[] = [
  {
    key: SortOptionKey.PRICE_ASC,
    label: SortOptionLabel.PRICE_ASC,
    id: SortOptionKey.PRICE_ASC
  },
  {
    key: SortOptionKey.PRICE_DESC,
    label: SortOptionLabel.PRICE_DESC,
    id: SortOptionKey.PRICE_DESC
  }
  // TODO - designs have it but not sure what value it adds / what we want it to do (random order??)
  // { key: SortOptionKey.CLEAR, label: 'Clear Sort', id: SortOptionKey.CLEAR }
];

const PropertyFilter: React.FC<PropertyFilter> = ({
  onFiltersUpdate,
  onSortUpdate,
  availablePropertyTypes = []
}) => {
  const [, setIsSorting] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [, setSortByLabel] = useState('Price - High to low');
  const [, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [priceRange, setPriceRange] = useState<number[]>([20, 37]);
  const [stagedFilters, setStagedFilters] = useState<Filters>({});

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    setPriceRange(newValue as number[]);
  };

  const handleSliderChangeCommitted = (
    _event: Event | React.SyntheticEvent<Element, Event>,
    newValue: number | number[]
  ) => {
    const [maxPriceLow, maxPriceHigh] = newValue as [number, number];
    setStagedFilters({
      ...stagedFilters,
      maxPriceLow,
      maxPriceHigh
    });
  };
  return (
    <>
      <Container>
        <div className={styles.searchControls}>
          <ButtonStandard
            type="button"
            color={COLOR_PRIMARY}
            aria-label="Filter"
            icon={isFiltering ? SVG_CLOSE : SVG_FILTER}
            iconPosition="left"
            onClick={() => {
              setIsFiltering(!isFiltering);
            }}
          >
            {isFiltering ? 'Close' : 'Filter'}
          </ButtonStandard>
          <Dropdown
            options={SORT_OPTIONS}
            onItemSelected={(id) => {
              setIsSorting(false);
              if (id === SortOptionKey.PRICE_ASC) {
                setSortOrder('asc');
                setSortByLabel(SortOptionLabel.PRICE_ASC);
                onSortUpdate({
                  fieldName: 'maxPrice',
                  order: 'asc'
                });
              } else if (id === SortOptionKey.PRICE_DESC) {
                setSortOrder('desc');
                setSortByLabel(SortOptionLabel.PRICE_DESC);
                onSortUpdate({
                  fieldName: 'maxPrice',
                  order: 'desc'
                });
              }
            }}
            onClose={() => setIsSorting(false)}
          />
        </div>
      </Container>

      {isFiltering && (
        <Container containerWidth="full" applyThemeBackground>
          <div className={styles.filters}>
            {availablePropertyTypes?.length > 0 && (
              <>
                <div className={styles.typeFilter}>
                  <Icon icon={SVG_BUSINESS} color={COLOR_SLATE} />
                  <p>Type</p>
                </div>

                <div className={styles.filterButtonsWrapper}>
                  {availablePropertyTypes.map((propertyType) => (
                    <ButtonStandard
                      key={propertyType}
                      fillVariant={
                        stagedFilters?.propertyTypes?.includes(propertyType)
                          ? 'filled'
                          : 'outline'
                      }
                      onClick={() => {
                        setStagedFilters({
                          ...stagedFilters,
                          propertyTypes: stagedFilters?.propertyTypes?.includes(
                            propertyType
                          )
                            ? stagedFilters.propertyTypes.filter(
                                (pt) => pt !== propertyType
                              )
                            : [
                                ...(stagedFilters.propertyTypes || []),
                                propertyType
                              ]
                        });
                      }}
                    >
                      {pascalToDisplay(propertyType)}
                    </ButtonStandard>
                  ))}
                </div>
              </>
            )}

            <div className={styles.priceFilterWrapper}>
              <div className={styles.priceFilterHeading}>
                <Icon icon={SVG_DOLLAR_SIGN} />
                <p>Price</p>
              </div>
              <Slider
                step={10000}
                className={styles.slider}
                getAriaLabel={() => 'price-range'}
                value={priceRange}
                onChange={handleSliderChange}
                onChangeCommitted={handleSliderChangeCommitted}
                max={FILTER_PRICE_HIGH}
                min={FILTER_PRICE_LOW}
                marks={[
                  {
                    value: priceRange?.[0] || FILTER_PRICE_LOW,
                    label: `$${(
                      priceRange?.[0] || FILTER_PRICE_LOW
                    )?.toLocaleString?.('en-US')}`
                  },
                  {
                    value: priceRange?.[1] || FILTER_PRICE_HIGH,
                    label: `$${(
                      priceRange?.[1] || FILTER_PRICE_HIGH
                    )?.toLocaleString?.('en-US')}`
                  }
                ]}
              />
            </div>
            <div className={styles.plusMinusFiltersSectionWrapper}>
              <Row>
                <Column xs={4} sm={4} md={8} lg={4} xl={4} xxl={4}>
                  <div className={styles.plusMinusFilterWrapper}>
                    <div className={styles.plusMinusFilterHeading}>
                      <Icon icon={SVG_BED} color={COLOR_SLATE} />
                      <p>Bedrooms</p>
                    </div>
                    <NumericStepper
                      id="bedrooms-stepper"
                      name="bedrooms"
                      min={1}
                      max={12}
                      defaultValue={stagedFilters?.numBeds || 1}
                      onValueChanged={(newValue) => {
                        setStagedFilters({
                          ...stagedFilters,
                          numBeds: newValue
                        });
                      }}
                    />
                  </div>
                </Column>
                <Column xs={4} sm={4} md={8} lg={4} xl={4} xxl={4}>
                  <div className={styles.plusMinusFilterWrapper}>
                    <div className={styles.plusMinusFilterHeading}>
                      <Icon icon={SVG_BATH} color={COLOR_SLATE} />
                      <p>Bathrooms</p>
                    </div>
                    <NumericStepper
                      id="bathrooms-stepper"
                      name="bathrooms"
                      min={1}
                      max={12}
                      defaultValue={stagedFilters?.numBaths || 1}
                      onValueChanged={(newValue) => {
                        setStagedFilters({
                          ...stagedFilters,
                          numBaths: newValue
                        });
                      }}
                    />
                  </div>
                </Column>
                <Column xs={4} sm={4} md={8} lg={4} xl={4} xxl={4}>
                  <div className={styles.plusMinusFilterWrapper}>
                    <div className={styles.plusMinusFilterHeading}>
                      <Icon icon={SVG_CAR} color={COLOR_SLATE} />
                      <p>Car spaces</p>
                    </div>
                    <NumericStepper
                      id="car-spaces-stepper"
                      name="carspaces"
                      min={1}
                      max={12}
                      defaultValue={stagedFilters?.numCarSpaces || 1}
                      onValueChanged={(newValue) => {
                        setStagedFilters({
                          ...stagedFilters,
                          numCarSpaces: newValue
                        });
                      }}
                    />
                  </div>
                </Column>
              </Row>
            </div>
            <div className={styles.bottomButtonsWrapper}>
              <ButtonStandard
                fillVariant="outline"
                onClick={() => {
                  setStagedFilters({});
                  onFiltersUpdate({});
                  setIsFiltering(false);
                }}
              >
                Clear
              </ButtonStandard>
              <ButtonStandard
                onClick={() => {
                  onFiltersUpdate(stagedFilters);
                  setIsFiltering(false);
                }}
              >
                Apply Filter
              </ButtonStandard>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default PropertyFilter;
