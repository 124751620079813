import { gql } from '@apollo/client';

export const galleryFragment = gql`
  fragment gallery on Gallery {
    __typename
    sys {
      ...sys
    }
    cloudinarySource
  }
`;

export const galleryFloorPlansFragment = gql`
  fragment galleryFloorPlans on GalleryFloorPlans {
    __typename
    sys {
      ...sys
    }
    cloudinarySource
  }
`;
