import { gql, TypedDocumentNode } from '@apollo/client';
import {
  contentfulMetadataFragment,
  pageCommunityDetailMetadataFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityNewsMetadataFragment,
  sysFragment
} from '@src/graphql/fragments';

export const pageCommunityNewsPathsQuery = gql`
  query pageCommunityNewsPaths {
    pageCommunityNewsCollection(
      where: { slug_not_in: ["/", "sitemap"] }
      limit: 1000
    ) {
      items {
        ...pageCommunityNewsHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityDetailMetadataFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;

/**
 * Get the page hyperlink URL/path for a specific page ID
 * This query uses $id as `pageCommunityNews` slugs are not unique for this content-type
 */
export const pageCommunityNewsPathQuery = gql`
  query pageCommunityNewsPath(
    $id: String
    $nextJsParamsHashId: String
    $preview: Boolean
  ) {
    pagePath: pageCommunityNewsCollection(
      where: {
        OR: [{ sys: { id: $id } }, { nextJsParamsHashId: $nextJsParamsHashId }]
      }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageCommunityNewsHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityDetailMetadataFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;
