import { ButtonLinkPill } from '@src/components/ButtonLink';
import MediaImage from '@src/components/MediaImage';
import Pill from '@src/components/Pill';
import Heading from '@src/elements/Heading';
import { CtaFragment, MediaImageFragment } from '@src/graphql/gql-types';
import { COLOR_BLUE, COLOR_PRIMARY } from '@src/types/colors';
import { getCTA } from '@src/utils/url';
import React from 'react';
import styles from './Tile.module.scss';

interface Tile {
  heading: string;
  description: string;
  image: MediaImageFragment;
  primaryCta: CtaFragment;
  option: number;
}

const Tile: React.FC<Tile> = ({
  heading,
  description,
  image,
  primaryCta,
  option
}) => {
  return (
    <div className={styles.tile}>
      {image && (
        <div className={styles.imgWrapper}>
          <MediaImage {...image} aspectRatio="1:1" thumb />
        </div>
      )}
      {option && (
        <div className={styles.pill}>
          <Pill color={COLOR_PRIMARY} text={`Option ${option}`} />
        </div>
      )}
      <Heading priority={3} fontStyle="h3">
        {heading}
      </Heading>
      {description && <p className={styles.description}>{description}</p>}
      {primaryCta && (
        <ButtonLinkPill
          {...getCTA(primaryCta)}
          color={COLOR_BLUE}
          aria-label={primaryCta.label || ''}
        >
          {primaryCta.label}
        </ButtonLinkPill>
      )}
    </div>
  );
};

export default Tile;
