import { QueryResult, useQuery } from '@apollo/client';
import {
  PageCommunityEventListFilterQuery,
  PageCommunityNewsListFilterQuery
} from '@src/graphql/gql-types';
import {
  pageCommunityNewsListFilterQuery,
  pageNationalNewsListFilterQuery,
  pageCommunityEventListFilterQuery
} from '@src/graphql/queries';
import {
  ListingType,
  NewsQueryContext,
  NewsEventQueries,
  NewsEventQueryVariables,
  isCommunityEventsQueryResult
} from '@src/types/newsAndEvents';
import { chunkArray } from './arrays';
import { getTodaysEarliestISOString } from './date';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';

export const useDataByListingTypeAndLevel = (
  type: ListingType,
  level: NewsQueryContext,
  limit: number,
  skip: number,
  slug?: string
): QueryResult<NewsEventQueries> => {
  // getTodaysEarliestISOString() returns todays date at midnight this morning
  const todaysMidnightIsoString = getTodaysEarliestISOString();
  const query = (() => {
    switch (type) {
      case 'events':
        return pageCommunityEventListFilterQuery;
      case 'news': {
        return level === 'community'
          ? pageCommunityNewsListFilterQuery
          : pageNationalNewsListFilterQuery;
      }
    }
  })();
  return useQuery<NewsEventQueries, NewsEventQueryVariables>(query, {
    variables: {
      communitySlug: slug,
      // eventDate is only for events - will be ignored by news query
      eventDate: todaysMidnightIsoString,
      preview: false,
      skip: skip,
      limit: limit
    },
    fetchPolicy: 'cache-and-network'
  });
};

/**
 * Takes in filter query results and sorts them by publish date depending on whether they are
 * community events or news.
 * @returns (community and shared news item or event item)[]
 */
export const sortCommunityResultsByPublishDate = (
  data: PageCommunityNewsListFilterQuery | PageCommunityEventListFilterQuery
) => {
  let items;

  // if data type is events
  if (isCommunityEventsQueryResult(data)) {
    items = data?.eventList?.items.flatMap((item) => [item || []]).flat();
  }

  // if data type is news
  if (!isCommunityEventsQueryResult(data)) {
    const sharedNews = (data.communitySharedNews || {})?.items;

    const combinedItems = [
      ...(sharedNews || []),
      ...(data.newsList?.items || [])
    ];
    items = combinedItems;
  }

  // sort by most recent to least recent if news otherwise return it as is (events are already ordered correctly from the query)
  const sortedItems = (items ?? []).sort((a, b) => {
    if (
      a?.__typename !== CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_EVENT &&
      b?.__typename !== CONTENTFUL_PAGE_CONTENT_TYPES.COMMUNITY_EVENT
    ) {
      return a?.publishDate > b?.publishDate
        ? -1
        : a?.publishDate < b?.publishDate
          ? 1
          : 0;
    }

    return 0;
  });

  return sortedItems;
};

/**
 * Take in the community news or events items and the shared news items then
 * merge, sort, and chunk depending on the page number passed
 * @returns (community and shared news item or event item)[]
 */
export const handleCommunityItems = (
  data: PageCommunityNewsListFilterQuery | PageCommunityEventListFilterQuery,
  limit: number,
  pageNumber: number
) => {
  const sortedItems = sortCommunityResultsByPublishDate(data);
  const chunkedList = chunkArray(sortedItems, limit);

  const currentChunkedList =
    chunkedList[pageNumber === 0 ? pageNumber : pageNumber - 1];

  return currentChunkedList;
};

/**
 * Calculate the total amount of pages for community news or events.
 * If it is news, then the number of pages is determined by the
 * community news items total plus shared news items total,
 * divided by limit
 * @returns number (amount of pages)
 */
export const handleCommunityTotal = (
  data: PageCommunityNewsListFilterQuery | PageCommunityEventListFilterQuery,
  limit: number
): number => {
  if (isCommunityEventsQueryResult(data)) {
    return Math.ceil((data?.eventList?.total ?? 0) / limit);
  }
  return Math.ceil(
    ((data?.newsList?.total || 0) +
      ((data?.communitySharedNews || {}).items?.map((item) => item?.sourcePage)
        .length || 0)) /
      limit
  );
};
