import { gql } from '@apollo/client';

export const alternatingGalleryBlockFragment = gql`
  fragment alternatingGalleryBlock on AlternatingGalleryBlock {
    __typename
    sys {
      ...sys
    }
    heading
    bodyContent {
      ...alternatingGalleryBlockRT
    }
    layout
    gallery {
      ...gallery
    }
    primaryCta {
      ...cta
    }
    secondaryCta {
      ...cta
    }
  }

  fragment alternatingGalleryBlockRT on AlternatingGalleryBlockBodyContent {
    json
    links {
      ...alternatingGalleryBlockRTLinks
    }
  }

  fragment alternatingGalleryBlockRTLinks on AlternatingGalleryBlockBodyContentLinks {
    entries {
      block {
        sys {
          ...sys
        }
      }
      inline {
        sys {
          ...sys
        }
        ...mediaAsset
      }
      hyperlink {
        ...hyperlink
        ...mediaAsset
      }
    }
  }
`;
