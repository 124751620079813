import { gql } from '@apollo/client';
import {
  sysFragment,
  navElFragment,
  pageCommunityDetailMetadataFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityStandardMetadataFragment,
  pageNationalStandardHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  pageNationalNewsHyperlinkFragment,
  pageNationalNewsMetadataFragment,
  pageCommunityDetailHyperlinkFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityNewsMetadataFragment,
  pageCommunityEventHyperlinkFragment,
  pageCommunityEventMetadataFragment,
  navSubElFragment,
  navFragment,
  hyperlinkFragment,
  contentfulMetadataFragment,
  pageCommunityDetailFragment,
  stateTerritoryFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityPropertyDetailMetadataFragment,
  mediaAssetFragment
} from '@src/graphql/fragments';

export const nationalBasePageQuery = gql`
  query nationalBasePage($preview: Boolean) {
    primaryNav: navigationCollection(
      preview: $preview
      where: { codeId: "national-primary" }
      limit: 1
    ) {
      items {
        ...nav
      }
    }

    footerNav: navigationCollection(
      preview: $preview
      where: { codeId: "national-footer" }
      limit: 1
    ) {
      items {
        navigationElementsCollection(limit: 10) {
          items {
            ...navEl
          }
        }
      }
    }

    socialLinks: navigationCollection(
      preview: $preview
      where: { codeId: "social-links" }
      limit: 1
    ) {
      items {
        navigationElementsCollection(limit: 10) {
          items {
            ...navEl
          }
        }
      }
    }

    legalLinks: navigationCollection(
      preview: $preview
      where: { codeId: "legal-links" }
      limit: 1
    ) {
      items {
        navigationElementsCollection(limit: 10) {
          items {
            ...navEl
          }
        }
      }
    }

    communities: pageCommunityDetailCollection(limit: 100, order: title_ASC) {
      items {
        ...pageCommunityDetail
      }
    }

    lookupPagesForNational: pageNationalStandardCollection(
      where: { slug_in: ["news"] }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageNationalStandardMetadata
      }
    }
  }

  ${sysFragment}
  ${hyperlinkFragment}
  ${contentfulMetadataFragment}
  ${pageCommunityDetailFragment}
  ${mediaAssetFragment}

  ${navFragment}
  ${navElFragment}
  ${navSubElFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}

  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}

  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityDetailMetadataFragment}

  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityEventHyperlinkFragment}

  ${pageCommunityPropertyDetailMetadataFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}

  ${stateTerritoryFragment}
`;
