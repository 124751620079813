import { gql, TypedDocumentNode } from '@apollo/client';
import {
  pageCommunityDetailMetadataFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityPropertyDetailMetadataFragment,
  sysFragment,
  productsHyperlinkFragment,
  contentfulMetadataFragment
} from '@src/graphql/fragments';

/**
 * Get the page hyperlink URL/path for a specific ID
 * This query uses $id as `product` has no slugs
 */
export const productPathQuery = gql`
  query productPath($id: String, $preview: Boolean) {
    productPath: entryCollection(
      where: { sys: { id: $id } }
      limit: 1
      preview: $preview
    ) {
      items {
        ...productsHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageCommunityPropertyDetailMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}

  ${productsHyperlinkFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;
