import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styles from './NewsEventsListingGrid.module.scss';
import { Container } from '@src/elements/Grid';
import Pagination from '@src/components/Pagination';
import { useViewport } from '@src/hooks/useViewport';
import { LG_BREAKPOINT } from '@src/constants/breakpoints';
import {
  handleCommunityItems,
  handleCommunityTotal,
  useDataByListingTypeAndLevel
} from '@src/utils/newsAndEvents';
import {
  CardFragments,
  NewsQueryContext,
  isCommunityNewsQueryResult,
  isCommunityEventsQueryResult
} from '@src/types/newsAndEvents';
import LoadingState from '@src/components/LoadingState';
import EventListingCard from '@src/components/Card/NewsEventListingCard/EventListingCard/EventListingCard';
import NewsListingCard from '@src/components/Card/NewsEventListingCard/NewsListingCard/NewsListingCard';
import Heading from '@src/elements/Heading';
import { PATHS } from '@src/constants/site';
import { getPageUrlPath } from '@src/utils/url';

enum PageSize {
  DESKTOP = 9,
  TABLET = 6
}

type NewsEventsListingGrid = {
  queryType: NewsQueryContext;
};

const NewsEventsListingGrid: React.FC<NewsEventsListingGrid> = ({
  queryType
}) => {
  const router = useRouter();
  const { width } = useViewport();
  const [numPages, setNumPages] = useState(0);
  const [defaultPage, setDefaultPage] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState(0);
  const pathName =
    queryType === 'national'
      ? `/${PATHS.NATIONAL_NEWS}`
      : `/${PATHS.COMMUNITY}/${router.query.slug_community_detail}/${PATHS.COMMUNITY_NEWS_AND_EVENTS}`;

  const [skipAmount, setSkipAmount] = useState(0);
  const [limitAmount, setLimitAmount] = useState(0);
  const [currentData, setCurrentData] =
    useState<(CardFragments | undefined | null)[]>();

  const defaultSelectedTab =
    router.query.selectedTab === 'events' ? 'events' : 'news';

  const [selectedTab, setSelectedTab] = useState<'news' | 'events'>(
    defaultSelectedTab
  );

  // check if url includes selectedTab params for events, if it doesn't set selected tab for the query as 'news'
  // if a different tab is selected reset the pagination page number to be the first page
  useEffect(() => {
    setSelectedTab(defaultSelectedTab);
    if (router.query.selectedTab !== selectedTab) {
      setDefaultPage(1);
    }
  }, [defaultSelectedTab, router.query.selectedTab]);

  // * If user happens to land on the page with a page query param, then set it in order to fetch the right data
  useEffect(() => {
    const pageNumber = parseInt(router.query.page as string) || 1;
    if (pageNumber <= numPages) {
      setDefaultPage(pageNumber);
    }
  }, [numPages, router, selectedTab]);

  // * Set the limit and skip for the query
  useEffect(() => {
    setLimitAmount(width < LG_BREAKPOINT ? PageSize.TABLET : PageSize.DESKTOP);
    setSkipAmount(
      defaultPage && defaultPage > 1
        ? defaultPage * limitAmount - limitAmount
        : 0
    );
  }, [defaultPage, limitAmount, width]);

  // * Query the news or event data
  const { data, loading, error } = useDataByListingTypeAndLevel(
    selectedTab,
    queryType,
    limitAmount,
    skipAmount,
    router.query.slug_community_detail as string
  );

  // * Set the correct current data depending on if 'community' or 'national' news OR 'community' events
  useEffect(() => {
    if (data && limitAmount > 0) {
      setCurrentData(
        handleCommunityItems(
          data,
          limitAmount,
          defaultPage === parseInt(router.query.page as string)
            ? defaultPage
            : currentPage
        )
      );
    }
    if (isCommunityNewsQueryResult(data)) {
      setNumPages(handleCommunityTotal(data, limitAmount));
    } else {
      if (isCommunityEventsQueryResult(data)) {
        setNumPages(Math.ceil((data?.eventList?.total || 0) / limitAmount));
      } else {
        setNumPages(Math.ceil((data?.newsList?.total || 0) / limitAmount));
      }
    }
  }, [
    currentPage,
    data,
    defaultPage,
    limitAmount,
    selectedTab,
    queryType,
    router.query.page
  ]);

  const handlePagination = useCallback(
    () => (
      <>
        {numPages > 1 && (
          <div className={styles.paginationWrapper}>
            <Pagination
              numPages={numPages}
              defaultPage={defaultPage}
              onPageChanged={(newPage) => {
                window.scrollTo({ top: 600, behavior: 'smooth' });

                setSkipAmount(
                  newPage > 1 ? newPage * limitAmount - limitAmount : 0
                );
                setCurrentPage(newPage);

                router.push(
                  {
                    pathname: pathName,
                    query: {
                      selectedTab: selectedTab,
                      page: newPage + 1
                    }
                  },
                  undefined,
                  { shallow: true }
                );
              }}
            />
          </div>
        )}
      </>
    ),
    [defaultPage, limitAmount, numPages, pathName, router]
  );

  if (error) {
    console.log('APOLLO ERROR: ', error);
  }

  return (
    <Container className={styles.listingContainer}>
      {loading && (
        <div className={styles.loadingState}>
          <LoadingState />
        </div>
      )}

      {!loading && !currentData?.length && (
        <div className={styles.emptyState}>
          <Heading priority={4} fontStyle="h3" data-testid="noEventsHeading">
            {selectedTab === 'events'
              ? 'There are currently no events scheduled. Please check again soon.'
              : 'There are currently no news items to display.'}
          </Heading>
        </div>
      )}

      {!loading && currentData?.length !== (0 || undefined) && (
        <div className={styles.newsEventsListingGrid}>
          {currentData?.map((item, index) => {
            const slug = (() => {
              if (item) {
                return getPageUrlPath(item);
              }
              return '';
            })();

            switch (item?.__typename) {
              case 'PageCommunityEvent':
                return (
                  <EventListingCard
                    key={`${item?.slug}_${index}`}
                    title={item?.title || ''}
                    hero={item?.hero}
                    eventDate={item?.eventDate}
                    eventEndDate={item?.eventEndDate}
                    slug={slug}
                  />
                );
              case 'PageSharedNews':
                return (
                  <NewsListingCard
                    key={`${item?.sourcePage?.slug}_${index}`}
                    title={item?.sourcePage?.title || ''}
                    hero={item?.sourcePage?.hero}
                    readingTime={item?.sourcePage?.readingTime ?? 0}
                    slug={slug}
                  />
                );
              default:
                return (
                  <NewsListingCard
                    key={`${item?.slug}_${index}`}
                    title={item?.title || ''}
                    hero={item?.hero}
                    readingTime={item?.readingTime ?? 0}
                    slug={slug}
                  />
                );
            }
          })}
        </div>
      )}

      {handlePagination()}
    </Container>
  );
};

export default NewsEventsListingGrid;
