import { gql } from '@apollo/client';

// ============= contentful ======================================
export const contentfulMetadataFragment = gql`
  fragment contentfulMetadata on Entry {
    contentfulMetadata {
      tags {
        __typename
        id
        name
      }
    }
  }
`;

// ============= National ======================================
export const pageNationalStandardMetadataFragment = gql`
  fragment pageNationalStandardMetadata on PageNationalStandard {
    __typename
    sys {
      ...sys
    }
    title
    slug
  }
`;

export const pageNationalNewsMetadataFragment = gql`
  fragment pageNationalNewsMetadata on PageNationalNews {
    __typename
    sys {
      ...sys
    }
    title
    slug
  }
`;

// ============= Community ======================================
// The fields here are needed for breadcrumbs, and making sure we have specific data for all nested pages within the community
export const pageCommunityDetailMetadataFragment = gql`
  fragment pageCommunityDetailMetadata on PageCommunityDetail {
    __typename
    ...contentfulMetadata
    sys {
      ...sys
    }
    title
    slug
    theme
    isLiveChatEnabled
    navigation {
      sys {
        id
      }
    }
    suburb
  }
`;

export const pageCommunityStandardMetadataFragment = gql`
  fragment pageCommunityStandardMetadata on PageCommunityStandard {
    __typename
    sys {
      ...sys
    }
    title
    slug
  }
`;

export const pageCommunityNewsMetadataFragment = gql`
  fragment pageCommunityNewsMetadata on PageCommunityNews {
    __typename
    sys {
      ...sys
    }
    title
    slug
  }
`;

export const pageCommunityEventMetadataFragment = gql`
  fragment pageCommunityEventMetadata on PageCommunityEvent {
    __typename
    sys {
      ...sys
    }
    title
    slug
  }
`;

export const pageSharedNewsMetadataFragment = gql`
  fragment pageSharedNewsMetadata on PageSharedNews {
    __typename
    sys {
      ...sys
    }
    publishDate
  }
`;

export const pageCommunityPropertyDetailMetadataFragment = gql`
  fragment pageCommunityPropertyDetailMetadata on PageCommunityPropertyDetail {
    __typename
    sys {
      ...sys
    }
    title
    slug
  }
`;
