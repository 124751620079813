import { Container } from '@src/elements/Grid';
import Heading from '@src/elements/Heading';
import { WordDictionary } from '@src/types/dictionary';
import { replaceStringWithDictionary } from '@src/utils/strings';
import React from 'react';
import styles from './IntroText.module.scss';

type IntroText = {
  introText: string;
  replacementDictionary?: WordDictionary;
};

const IntroText: React.FC<IntroText> = ({
  introText,
  replacementDictionary
}) => {
  const dspText = replacementDictionary
    ? replaceStringWithDictionary(introText, replacementDictionary)
    : introText;
  return (
    <>
      {dspText && (
        <Container>
          <div className={styles.introText}>
            <Heading priority={3} fontStyle="h3">
              {dspText}
            </Heading>
          </div>
        </Container>
      )}
    </>
  );
};

export default IntroText;
