import { gql } from '@apollo/client';

export const videoBlockFragment = gql`
  fragment videoBlock on VideoBlock {
    __typename
    sys {
      ...sys
    }
    heading
    description
    applyTheme
    mediaVideo {
      ...mediaVideo
    }
  }
`;
