import { gql } from '@apollo/client';

export const contactPeopleFragment = gql`
  fragment contactPeople on ContactPerson {
    __typename
    sys {
      id
    }
    name
    position
    phoneNumber
    email
    assets: image {
      ... on MediaImage {
        imageSource: cloudinarySource
        __typename
      }
      __typename
    }
  }
`;
