// ==== Site wide =======
export * from './globalSettings.query';

// ==== National =======
export * from './nationalBasePage.query';
export * from './pageNationalStandard.query';
export * from './pageNationalStandardPaths.query';
export * from './pageNationalNews.query';
export * from './pageNationalNewsPaths.query';

// ==== Community =======
export * from './communityBasePage.query';
export * from './pageCommunityStandard.query';
export * from './pageCommunityStandardPaths.query';
export * from './pageCommunityDetail.query';
export * from './pageCommunityDetailPaths.query';
export * from './pageCommunityNews.query';
export * from './pageCommunityNewsPaths.query';
export * from './pageCommunityEvent.query';
export * from './pageCommunityEventPaths.query';
export * from './pageCommunityPropertyDetail.query';
export * from './pageCommunityPropertyDetailPaths.query';
export * from './productPaths.query';

// ==== Shared =======
export * from './pageSharedNews.query';
export * from './pageSharedNewsPaths.query';
