import Content from '@src/elements/Content';
import { Column, Row } from '@src/elements/Grid';
import Heading from '@src/elements/Heading';
import { SectionTitleBlockFragment } from '@src/graphql/gql-types';
import { ContentfulPageTypes } from '@src/types/contentful';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import classNames from 'classnames';
import React from 'react';
import RichTextRender from '@src/components/RichTextRender';
import styles from './SectionTitle.module.scss';
import { ContentBlocksData } from '@src/types/contentBlocks';
import { replaceStringWithDictionary } from '@src/utils/strings';

// Rich Text
type RTSectionTitle = SectionTitleBlockFragment & {
  left?: boolean;
  pageType: ContentfulPageTypes;
  sharedBuildData?: ContentBlocksData;
};

// Plain Text
type PTSectionTitle = {
  heading: string;
  description?: string;
};

type SectionTitle = RTSectionTitle | PTSectionTitle;

const SectionTitle: React.FC<SectionTitle> = (props) => {
  const { heading, bodyContent, left, pageType, sharedBuildData } =
    props as RTSectionTitle;
  const replacementDictionary = sharedBuildData?.replacementDictionary;
  const description = (props as PTSectionTitle).description;
  const headingText = replacementDictionary
    ? replaceStringWithDictionary(heading, replacementDictionary)
    : heading;
  const descriptionText = replacementDictionary
    ? replaceStringWithDictionary(description, replacementDictionary)
    : description;

  return (
    <div
      className={classNames(styles.sectionTitle, {
        [styles.textLeft]: left
      })}
    >
      <Row>
        <Column xl={6} offsetXl={3}>
          <Content>
            <Heading priority={2} fontStyle={'h2'}>
              {headingText}
            </Heading>
            {bodyContent && (
              <RichTextRender
                bodyContent={bodyContent}
                pageType={pageType}
                renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                replacementDictionary={replacementDictionary}
              />
            )}
            {descriptionText && (
              <div
                className={classNames(styles.description, {
                  [styles.textLeft]: left
                })}
              >
                <p>{descriptionText}</p>
              </div>
            )}
          </Content>
        </Column>
      </Row>
    </div>
  );
};

export default SectionTitle;
