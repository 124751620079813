import { gql } from '@apollo/client';

export const alternatingTextBlockFragment = gql`
  fragment alternatingTextBlock on AlternatingTextBlock {
    __typename
    sys {
      ...sys
    }
    heading
    bodyContent {
      ...alternatingTextBlockRT
    }
    layout
    mediaItem {
      ...mediaImage
      ...mediaVideo
    }
    primaryCta {
      ...cta
    }
    secondaryCta {
      ...cta
    }
  }

  fragment alternatingTextBlockRT on AlternatingTextBlockBodyContent {
    json
    links {
      ...alternatingTextBlockRTLinks
    }
  }

  fragment alternatingTextBlockRTLinks on AlternatingTextBlockBodyContentLinks {
    entries {
      block {
        sys {
          ...sys
        }
      }
      inline {
        sys {
          ...sys
        }
        ...mediaAsset
      }
      hyperlink {
        ...hyperlink
        ...mediaAsset
      }
    }
  }
`;
