import { gql, TypedDocumentNode } from '@apollo/client';
import {
  sysFragment,
  pageCommunityNewsMetadataFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityDetailMetadataFragment,
  hyperlinkFragment,
  pageCommunityDetailHyperlinkFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityStandardMetadataFragment,
  pageNationalNewsHyperlinkFragment,
  pageNationalNewsMetadataFragment,
  pageNationalStandardHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  contentfulMetadataFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityPropertyDetailMetadataFragment,
  newsListingCardCommunityFragment,
  newsListingCardNationalFragment,
  mediaAssetFragment,
  mediaImageFragment,
  mediaVideoFragment,
  heroSmallFragment,
  relatedUrlLinksFragment,
  newsRelatedLinkFragment,
  galleryFragment,
  pageCommunityEventMetadataFragment,
  pageCommunityEventHyperlinkFragment,
  heroCardImageFragment
} from '@src/graphql/fragments';

export const pageCommunityNewsFieldsFragment = gql`
  fragment pageCommunityNewsFields on PageCommunityNews {
    sys {
      publishedAt
      ...sys
    }
    title
    slug
    community {
      isLiveChatEnabled
    }
    hero {
      ...heroSmall
    }
    publishDate
    readingTime
    downloadablePdFsCollection(limit: 5) {
      items {
        ...mediaAsset
      }
    }
    relatedLinksCollection(limit: 10) {
      items {
        ...newsRelatedLink
      }
    }
    introText
    bodyContent {
      ...pageCommunityNewsRTL
    }
    seoTitle
    seoDescription
    noIndex
    noFollow
  }
`;

export const pageCommunityNewsRTLFragment = gql`
  fragment pageCommunityNewsRTL on PageCommunityNewsBodyContent {
    json
    links {
      ...pageCommunityNewsRTLLinks
    }
  }

  fragment pageCommunityNewsRTLLinks on PageCommunityNewsBodyContentLinks {
    entries {
      block {
        sys {
          ...sys
        }
        ...mediaImage
        ...mediaVideo
        ...gallery
      }
      inline {
        sys {
          ...sys
        }
        ...mediaAsset
      }
      hyperlink {
        ...hyperlink
        ...mediaAsset
      }
    }
  }
`;

/**
 * This query uses $id as `pageCommunityNews` slugs are not unique for this content-type
 * Example WHERE OR statement
 *  - WHERE { OR: [{ slug: $slug }, { sys: { id: $id } }] }
 */
export const pageCommunityNewsQuery = gql`
  query pageCommunityNews($id: String, $preview: Boolean) {
    page: pageCommunityNewsCollection(
      where: { sys: { id: $id } }
      preview: $preview
      limit: 1
    ) {
      items {
        ...pageCommunityNewsFields
      }
    }
    pagePath: pageCommunityNewsCollection(
      where: { sys: { id: $id } }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageCommunityNewsHyperlink
      }
    }
  }

  ${pageCommunityNewsFieldsFragment}
  ${pageCommunityNewsRTLFragment}

  ${sysFragment}
  ${hyperlinkFragment}
  ${contentfulMetadataFragment}

  ${mediaImageFragment}
  ${mediaVideoFragment}
  ${mediaAssetFragment}
  ${galleryFragment}
  ${heroSmallFragment}
  ${relatedUrlLinksFragment}
  ${newsRelatedLinkFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;

/**
 * Query for getting the community news and shared news
 * type: PageCommunityNewsListFilterQuery
 * Example query call:
 *  apolloClient.query<PageCommunityNewsListFilterQuery>({
      query: pageCommunityNewsListFilterQuery,
      variables: { communitySlug: params?.slug_community_detail }
    })
 */
export const pageCommunityNewsListFilterQuery = gql`
  query pageCommunityNewsListFilter($communitySlug: String, $preview: Boolean) {
    newsList: pageCommunityNewsCollection(
      where: { community: { slug: $communitySlug } }
      preview: $preview
      limit: 1000
      order: publishDate_DESC
    ) {
      total
      items {
        ...newsListingCardCommunity
        ...pageCommunityNewsMetadata
        parentPage: community {
          ...pageCommunityDetailMetadata
        }
      }
    }

    communitySharedNews: pageSharedNewsCollection(
      limit: 50
      where: { community: { slug: $communitySlug } }
      order: publishDate_DESC
      preview: $preview
    ) {
      items {
        sourcePage {
          ...newsListingCardNational
        }
        publishDate
        parentPage: community {
          ...pageCommunityDetailMetadata
        }
      }
    }
  }
  ${sysFragment}

  ${contentfulMetadataFragment}
  ${heroCardImageFragment}

  ${pageNationalNewsMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}

  ${pageCommunityNewsHyperlinkFragment}

  ${newsListingCardNationalFragment}
  ${newsListingCardCommunityFragment}
` as TypedDocumentNode;
