import React from 'react';
import styles from './MediaImageBlock.module.scss';
import { MediaImageFragment } from '@src/graphql/gql-types';

import MediaImage from '@src/components/MediaImage';

type MediaImageBlock = MediaImageFragment;

const MediaImageBlock: React.FC<MediaImageBlock> = (mediaImage) => {
  return (
    <div className={styles.mediaImageBlock}>
      <div className={styles.content}>
        <div className={styles.mediaImageBlockWrapper}>
          {mediaImage && <MediaImage {...mediaImage} />}
        </div>
      </div>
    </div>
  );
};

export default MediaImageBlock;
