import { gql } from '@apollo/client';

export const sysFragment = gql`
  fragment sys on Sys {
    id
  }
`;

export const sysPageFragment = gql`
  fragment sysPage on Sys {
    id
    firstPublishedAt
    publishedAt
  }
`;
