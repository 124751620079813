import { gql, TypedDocumentNode } from '@apollo/client';
import {
  sysFragment,
  pageCommunityNewsMetadataFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityDetailMetadataFragment,
  hyperlinkFragment,
  pageCommunityDetailHyperlinkFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityStandardMetadataFragment,
  pageNationalNewsHyperlinkFragment,
  pageNationalNewsMetadataFragment,
  pageNationalStandardHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  contentfulMetadataFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityPropertyDetailMetadataFragment,
  pageSharedNewsHyperlinkFragment,
  pageSharedNewsMetadataFragment,
  heroSmallFragment,
  mediaAssetFragment,
  mediaImageFragment,
  mediaVideoFragment,
  newsRelatedLinkFragment,
  relatedUrlLinksFragment,
  galleryFragment,
  pageCommunityEventHyperlinkFragment,
  pageCommunityEventMetadataFragment
} from '@src/graphql/fragments';
import {
  pageNationalNewsFieldsFragment,
  pageNationalNewsRTLFragment
} from '@src/graphql/queries/pageNationalNews.query';

/**
 * This query uses $id
 */
export const pageSharedNewsQuery = gql`
  query pageSharedNews($id: String, $preview: Boolean) {
    page: pageSharedNewsCollection(
      where: { sys: { id: $id } }
      preview: $preview
      limit: 1
    ) {
      items {
        publishDate
        community {
          isLiveChatEnabled
        }
        sourcePage {
          ...pageNationalNewsFields
        }
      }
    }
    pagePath: pageSharedNewsCollection(
      where: { sys: { id: $id } }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageSharedNewsHyperlink
      }
    }
  }
  ${pageNationalNewsFieldsFragment}
  ${pageNationalNewsRTLFragment}

  ${sysFragment}
  ${hyperlinkFragment}
  ${contentfulMetadataFragment}

  ${mediaImageFragment}
  ${mediaVideoFragment}
  ${mediaAssetFragment}
  ${galleryFragment}
  ${heroSmallFragment}
  ${relatedUrlLinksFragment}
  ${newsRelatedLinkFragment}

  ${pageSharedNewsMetadataFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageSharedNewsHyperlinkFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;
