import { gql, TypedDocumentNode } from '@apollo/client';
import {
  contentfulMetadataFragment,
  heroSmallFragment,
  hyperlinkFragment,
  mediaAssetFragment,
  mediaImageFragment,
  mediaVideoFragment,
  pageCommunityDetailHyperlinkFragment,
  pageCommunityDetailMetadataFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityNewsMetadataFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityPropertyDetailMetadataFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityStandardMetadataFragment,
  pageNationalNewsHyperlinkFragment,
  pageNationalNewsMetadataFragment,
  pageNationalStandardHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  newsRelatedLinkFragment,
  sysFragment,
  relatedUrlLinksFragment,
  newsListingCardNationalFragment,
  galleryFragment,
  pageCommunityEventHyperlinkFragment,
  pageCommunityEventMetadataFragment,
  heroCardImageFragment
} from '@src/graphql/fragments';

export const pageNationalNewsFieldsFragment = gql`
  fragment pageNationalNewsFields on PageNationalNews {
    sys {
      publishedAt
      ...sys
    }
    title
    slug
    hero {
      ...heroSmall
    }
    publishDate
    readingTime
    downloadablePdFsCollection(limit: 5) {
      items {
        ...mediaAsset
      }
    }
    relatedLinksCollection(limit: 10) {
      items {
        ...newsRelatedLink
      }
    }
    introText
    bodyContent {
      ...pageNationalNewsRTL
    }
    seoTitle
    seoDescription
    noIndex
    noFollow
  }
`;

export const pageNationalNewsRTLFragment = gql`
  fragment pageNationalNewsRTL on PageNationalNewsBodyContent {
    json
    links {
      ...pageNationalNewsRTLLinks
    }
  }

  fragment pageNationalNewsRTLLinks on PageNationalNewsBodyContentLinks {
    entries {
      block {
        sys {
          ...sys
        }
        ...mediaImage
        ...mediaVideo
        ...gallery
      }
      inline {
        sys {
          ...sys
        }
        ...mediaAsset
      }
      hyperlink {
        ...hyperlink
        ...mediaAsset
      }
    }
  }
`;

export const pageNationalNewsQuery = gql`
  query pageNationalNews($slug: String, $preview: Boolean) {
    page: pageNationalNewsCollection(
      where: { slug: $slug }
      preview: $preview
      limit: 1
    ) {
      items {
        ...pageNationalNewsFields
      }
    }

    pagePath: pageNationalNewsCollection(
      where: { slug: $slug }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageNationalNewsMetadata
      }
    }
  }

  ${pageNationalNewsFieldsFragment}
  ${pageNationalNewsRTLFragment}

  ${sysFragment}
  ${hyperlinkFragment}
  ${contentfulMetadataFragment}

  ${mediaImageFragment}
  ${mediaVideoFragment}
  ${mediaAssetFragment}
  ${galleryFragment}
  ${heroSmallFragment}
  ${relatedUrlLinksFragment}
  ${newsRelatedLinkFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;

/**
 * Query for National News listings / cards
 */
export const pageNationalNewsListFilterQuery = gql`
  query pageNationalNewsListFilter($preview: Boolean, $skip: Int, $limit: Int) {
    newsList: pageNationalNewsCollection(
      where: { showInNationalNewsListing: true }
      preview: $preview
      skip: $skip
      limit: $limit
      order: publishDate_DESC
    ) {
      total
      items {
        ...newsListingCardNational
      }
    }
  }

  ${sysFragment}
  ${heroCardImageFragment}

  ${newsListingCardNationalFragment}

  ${contentfulMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageNationalNewsMetadataFragment}

  ${pageCommunityNewsHyperlinkFragment}
` as TypedDocumentNode;
