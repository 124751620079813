import { gql, TypedDocumentNode } from '@apollo/client';
import {
  contentfulMetadataFragment,
  pageCommunityDetailMetadataFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityStandardMetadataFragment,
  sysFragment
} from '@src/graphql/fragments';

export const pageCommunityStandardPathsQuery = gql`
  query pageCommunityStandardPaths {
    pageCommunityStandardCollection(
      where: { slug_not_in: ["/", "sitemap"] }
      limit: 1000
    ) {
      items {
        ...pageCommunityStandardHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityDetailMetadataFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;

/**
 * Get the page hyperlink URL/path for a specific page ID
 * This query uses $id as `pageCommunityStandard` slugs are not unique unique for this content-type
 */
export const pageCommunityStandardPathQuery = gql`
  query pageCommunityStandardPath(
    $id: String
    $nextJsParamsHashId: String
    $preview: Boolean
  ) {
    pagePath: pageCommunityStandardCollection(
      where: {
        OR: [{ sys: { id: $id } }, { nextJsParamsHashId: $nextJsParamsHashId }]
      }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageCommunityStandardHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityDetailMetadataFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;
