import LinkWrapper from '@src/components/LinkWrapper';
import MediaImage from '@src/components/MediaImage';
import Heading from '@src/elements/Heading';
import { MediaAssetFragment, MediaImageFragment } from '@src/graphql/gql-types';
import { ContentfulPageHyperlinkFragments } from '@src/types/contentful';
import { getLink, getOpenNewWindow } from '@src/utils/url';
import React from 'react';
import styles from './DiscoverCard.module.scss';

interface DiscoverCard {
  heading: string;
  image: MediaImageFragment;
  internalLink: ContentfulPageHyperlinkFragments;
  urlLink: string;
  assetLink: MediaAssetFragment;
}

const DiscoverCard: React.FC<DiscoverCard> = ({
  heading,
  image,
  internalLink,
  urlLink,
  assetLink
}) => {
  return (
    <div className={styles.discoverCard}>
      <LinkWrapper
        href={getLink(internalLink, urlLink, assetLink)}
        openNewWindow={getOpenNewWindow(internalLink, urlLink, assetLink)}
      >
        <div className={styles.backgroundMediaWrapper}>
          <MediaImage {...image} />
        </div>
        <div className={styles.background}>
          <div className={styles.backgroundBlock}></div>
          <svg className={styles.backgroundTriangle} viewBox="0 0 278 769">
            <polygon points="278 0, 0 769, 278 769" />
          </svg>
        </div>
        {heading && (
          <div className={styles.headingWrapper}>
            <Heading priority={4} fontStyle="h4">
              {heading}
            </Heading>
          </div>
        )}
      </LinkWrapper>
    </div>
  );
};

export default DiscoverCard;
