import { gql, TypedDocumentNode } from '@apollo/client';
import {
  sysFragment,
  pageCommunityEventHyperlinkFragment,
  pageCommunityDetailMetadataFragment,
  contentfulMetadataFragment,
  eventListingCardCommunityFragment,
  mediaImageFragment,
  heroSmallFragment,
  pageCommunityEventMetadataFragment,
  hyperlinkFragment,
  mediaAssetFragment,
  pageNationalStandardHyperlinkFragment,
  pageNationalNewsHyperlinkFragment,
  pageCommunityDetailHyperlinkFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  pageNationalNewsMetadataFragment,
  pageCommunityNewsMetadataFragment,
  pageCommunityPropertyDetailMetadataFragment,
  pageCommunityStandardMetadataFragment,
  heroCardImageFragment
} from '@src/graphql/fragments';

export const pageCommunityEventFieldsFragment = gql`
  fragment pageCommunityEventFields on PageCommunityEvent {
    sys {
      publishedAt
      ...sys
    }
    title
    hero {
      ...heroSmall
    }
    community {
      title
      state {
        slug
      }
      isLiveChatEnabled
    }
    description {
      ...pageCommunityEventRTL
    }
    contactPeople: contactPeopleCollection {
      items {
        name
        phoneNumber
      }
    }
    pinAddress {
      lat
      lon
    }
    displayAddress
    displayRsvpForm
    eventDate
    startTime
    endTime
    freeTextTime
    slug
    nextJsParamsHashId
    parentPage: community {
      ...pageCommunityDetailMetadata
    }
  }
`;

export const pageCommunityEventRTLFragment = gql`
  fragment pageCommunityEventRTL on PageCommunityEventDescription {
    json
    links {
      ...pageCommunityEventRTLLinks
    }
  }

  fragment pageCommunityEventRTLLinks on PageCommunityEventDescriptionLinks {
    entries {
      hyperlink {
        ...hyperlink
      }
    }
  }
`;

/**
 * This query uses $id as `pageCommunityEvent` slugs are not unique for this content-type
 * Example WHERE OR statement
 *  - WHERE { OR: [{ slug: $slug }, { sys: { id: $id } }] }
 */
export const pageCommunityEventQuery = gql`
  query pageCommunityEvent($id: String, $preview: Boolean) {
    page: pageCommunityEventCollection(
      where: { sys: { id: $id } }
      preview: $preview
      limit: 1
    ) {
      items {
        ...pageCommunityEventFields
      }
    }
    pagePath: pageCommunityEventCollection(
      where: { sys: { id: $id } }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageCommunityEventHyperlink
      }
    }
  }

  ${pageCommunityEventFieldsFragment}

  ${sysFragment}
  ${mediaAssetFragment}
  ${contentfulMetadataFragment}
  ${pageCommunityEventRTLFragment}

  ${mediaImageFragment}
  ${heroSmallFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${hyperlinkFragment}
  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
` as TypedDocumentNode;

/**
 * Query for getting the community events
 * type: PageCommunityEventListFilterQuery
 * Example query call:
 *  apolloClient.query<PageCommunityEventListFilterQuery>({
      query: PageCommunityEventListFilterQuery,
      variables: { communitySlug: params?.slug_community_detail }
    })
 */

export const pageCommunityEventListFilterQuery = gql`
  query pageCommunityEventListFilter(
    $communitySlug: String
    $preview: Boolean
    $eventDate: DateTime
  ) {
    eventList: pageCommunityEventCollection(
      where: { community: { slug: $communitySlug }, eventDate_gte: $eventDate }
      preview: $preview
      limit: 1000
      order: eventDate_ASC
    ) {
      total
      items {
        ...eventListingCardCommunity
      }
    }
  }

  ${sysFragment}

  ${heroCardImageFragment}

  ${pageCommunityEventMetadataFragment}
  ${contentfulMetadataFragment}
  ${pageCommunityDetailMetadataFragment}

  ${eventListingCardCommunityFragment}
` as TypedDocumentNode;
