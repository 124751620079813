import { gql, TypedDocumentNode } from '@apollo/client';
import {
  contentfulMetadataFragment,
  pageCommunityDetailMetadataFragment,
  pageNationalNewsMetadataFragment,
  pageSharedNewsHyperlinkFragment,
  pageSharedNewsMetadataFragment,
  sysFragment
} from '@src/graphql/fragments';

export const pageSharedNewsPathsQuery = gql`
  query pageSharedNewsPaths {
    pageSharedNewsCollection(
      where: { sourcePage: { slug_not_in: ["/", "sitemap"] } }
      limit: 1000
    ) {
      items {
        ...pageSharedNewsHyperlink
      }
    }
  }
  ${sysFragment}
  ${contentfulMetadataFragment}

  ${pageSharedNewsMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}

  ${pageSharedNewsHyperlinkFragment}
` as TypedDocumentNode;

/**
 * Get the page hyperlink URL/path for a specific page ID
 */
export const pageSharedNewsPathQuery = gql`
  query pageSharedNewsPath(
    $id: String
    $nextJsParamsHashId: String
    $preview: Boolean
  ) {
    pagePath: pageSharedNewsCollection(
      where: {
        OR: [{ sys: { id: $id } }, { nextJsParamsHashId: $nextJsParamsHashId }]
      }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageSharedNewsHyperlink
      }
    }
  }
  ${sysFragment}
  ${contentfulMetadataFragment}

  ${pageSharedNewsMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}

  ${pageSharedNewsHyperlinkFragment}
` as TypedDocumentNode;
