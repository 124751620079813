import { gql, TypedDocumentNode } from '@apollo/client';
import {
  pageNationalStandardHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  sysFragment
} from '@src/graphql/fragments';

export const pageNationalStandardPathsQuery = gql`
  query pageNationalStandardPaths {
    pageNationalStandardCollection(where: { slug_not_in: ["/"] }, limit: 1000) {
      items {
        ...pageNationalStandardHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageNationalStandardMetadataFragment}
  ${pageNationalStandardHyperlinkFragment}
` as TypedDocumentNode;

/**
 * Get a URL for a specific slug
 */
export const pageNationalStandardPathQuery = gql`
  query pageNationalStandardPath(
    $slug: String
    $id: String
    $preview: Boolean
  ) {
    pagePath: pageNationalStandardCollection(
      where: { OR: [{ slug: $slug }, { sys: { id: $id } }] }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageNationalStandardHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageNationalStandardMetadataFragment}
  ${pageNationalStandardHyperlinkFragment}
` as TypedDocumentNode;
