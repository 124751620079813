import { gql } from '@apollo/client';

export const mediaAssetFragment =
  /* GraphQL */
  gql`
    fragment mediaAsset on MediaAsset {
      __typename
      sys {
        ...sys
      }
      caption
      cloudinarySource
    }
  `;

export const mediaImageFragment =
  /* GraphQL */
  gql`
    fragment mediaImage on MediaImage {
      __typename
      sys {
        ...sys
      }
      caption
      cloudinarySource
    }
  `;

export const mediaVideoFragment =
  /* GraphQL */
  gql`
    fragment mediaVideo on MediaVideo {
      __typename
      sys {
        ...sys
      }
      caption
      coverImage
      cloudinaryVideoSource
      externalUrl
    }
  `;
