import { gql } from '@apollo/client';

export const communityContactUsFragment = gql`
  fragment communityContactUsBlock on CommunityContactUsBlock {
    __typename
    contactPeopleCollection {
      items {
        ...contactPeople
      }
    }
    addressHeading
    address
    location {
      lat
      lon
    }
  }
`;
