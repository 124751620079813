import { gql } from '@apollo/client';

export const CTAFragment =
  /* GraphQL */
  gql`
    fragment cta on CallToAction {
      __typename
      sys {
        ...sys
      }
      label
      openInNewWindow
      internalLink {
        ...hyperlink
      }
      urlLink
      assetLink {
        ...mediaAsset
        ...mediaVideo
      }
      modalId
    }
  `;
