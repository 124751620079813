import { gql } from '@apollo/client';

export const twoColumnBlockFragment = gql`
  fragment twoColumnBlock on TwoColumnBlock {
    __typename
    sys {
      ...sys
    }
    heading
    description
    applyTheme
    leftColumnHeading
    leftColumnBodyContent {
      ...twoColumnLeftBlockRT
    }
    leftColumnPrimaryCallToAction {
      ...cta
    }
    rightColumnHeading
    rightColumnBodyContent {
      ...twoColumnRightBlockRT
    }
    rightColumnPrimaryCallToAction {
      ...cta
    }
  }

  fragment twoColumnLeftBlockRT on TwoColumnBlockLeftColumnBodyContent {
    json
    links {
      ...twoColumnLeftBlockRTLinks
    }
  }

  fragment twoColumnRightBlockRT on TwoColumnBlockRightColumnBodyContent {
    json
    links {
      ...twoColumnRightBlockRTLinks
    }
  }

  fragment twoColumnLeftBlockRTLinks on TwoColumnBlockLeftColumnBodyContentLinks {
    entries {
      block {
        sys {
          ...sys
        }
      }
      inline {
        sys {
          ...sys
        }
        ...mediaAsset
      }
      hyperlink {
        ...hyperlink
        ...mediaAsset
      }
    }
  }

  fragment twoColumnRightBlockRTLinks on TwoColumnBlockRightColumnBodyContentLinks {
    entries {
      block {
        sys {
          ...sys
        }
      }
      inline {
        sys {
          ...sys
        }
        ...mediaAsset
      }
      hyperlink {
        ...hyperlink
        ...mediaAsset
      }
    }
  }
`;
