import { useState, useEffect } from 'react';

/**
 * @returns window innerWidth
 */
export const useViewport = () => {
  const initialWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  const [width, setWidth] = useState<number>(initialWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width };
};
