import Heading from '@src/elements/Heading';
import React from 'react';
import styles from './Discover.module.scss';
import { DiscoverBlockFragment } from '@src/graphql/gql-types';
import DiscoverCard from './DiscoverCard';
import { Container } from '@src/elements/Grid';

type Discover = DiscoverBlockFragment;

const Discover: React.FC<Discover> = ({
  heading,
  description,
  cardsCollection
}) => {
  const items = cardsCollection?.items;
  return (
    <div className={styles.discover}>
      <Container containerWidth="fluid">
        <div className={styles.discoverBody}>
          {heading && description && (
            <div
              className={styles.discoverContent}
              data-testid="discoveryHeader"
            >
              <Heading priority={2} fontStyle="h2">
                {heading}
              </Heading>
              <p>{description}</p>
            </div>
          )}
          {items && (
            <div className={styles.cardContainer} data-testid="discoveryItems">
              {items?.map((item: any) => (
                <DiscoverCard key={item.heading} {...item} />
              ))}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Discover;
