import { gql, TypedDocumentNode } from '@apollo/client';
import {
  CTAFragment,
  hyperlinkFragment,
  mediaAssetFragment,
  mediaVideoFragment,
  pageCommunityDetailHyperlinkFragment,
  pageCommunityDetailMetadataFragment,
  pageCommunityEventHyperlinkFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityNewsMetadataFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityStandardMetadataFragment,
  pageNationalNewsHyperlinkFragment,
  pageNationalNewsMetadataFragment,
  pageNationalStandardHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  sysFragment
} from '@src/graphql/fragments';
import {
  globalSettingsMetadataFragment,
  globalSettingItemFragment
} from '@src/graphql/fragments/globalSettings.fragment';
import {
  contentfulMetadataFragment,
  pageCommunityEventMetadataFragment,
  pageCommunityPropertyDetailMetadataFragment
} from '@src/graphql/fragments/pageMetadata.fragment';

/**
 * Get the global settings content type on site level
 */
export const globalSettingsQuery = gql`
  query globalSettings($preview: Boolean) {
    globalSettings: globalSettingsCollection(
      preview: $preview
      where: { active: true }
      limit: 10
    ) {
      items {
        ...globalSettingItem
      }
    }
  }

  ${sysFragment}
  ${hyperlinkFragment}
  ${contentfulMetadataFragment}
  ${CTAFragment}
  ${mediaAssetFragment}
  ${mediaVideoFragment}
  ${globalSettingsMetadataFragment}
  ${globalSettingItemFragment}

  ${pageNationalNewsMetadataFragment}
  ${pageNationalStandardMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;
