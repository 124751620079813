import { MapListingGrid } from '@src/components/GridList';
import styles from './MapListingContainer.module.scss';
import Map from '@src/components/Map';
import { getLastSegmentFromURL } from '@src/utils/url';
import React, { useState, useEffect, useRef } from 'react';
import Heading from '@src/elements/Heading';
import Pagination from '@src/components/Pagination';
import classNames from 'classnames';
import { ButtonStandard } from '@src/components/Button';
import { getMapData } from '@src/utils/getMapData';
import { useRouter } from 'next/router';

const MapListingContainer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [mobileMapVisible, setMobileMapVisible] = useState(false);
  const [mapData, setMapData] = useState([]);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const stateAcronym = getLastSegmentFromURL(router)?.toUpperCase();
  const icon = mobileMapVisible ? 'List' : 'MapMarker';
  const itemsPerPage = 6;
  let resultsCount;
  let resultText;
  let numPages = 0;
  let listingsData: Array<any> = [];

  const changePage = (pageNum: number) => {
    setCurrentPage(pageNum + 1);
    scrollToRef();
  };

  const toggleMobileMapVisible = () => {
    setMobileMapVisible(!mobileMapVisible);
  };

  function* chunkArray(arr: Array<any>, n: number) {
    for (let i = 0; i < arr.length; i += n) {
      yield arr.slice(i, i + n);
    }
  }
  // Sort array by alphabetical order
  const sortMapData = (data: Array<any>) => {
    return data.sort(function (a, b) {
      return a.title.localeCompare(b.title);
    });
  };
  const initData = () => {
    resultsCount = mapData.length ? mapData.length : 'No';
    resultText = <p>{resultsCount} communities matching</p>;
    numPages = Math.ceil(mapData.length / itemsPerPage);
    const sortedData = sortMapData(mapData);
    const listingsDataChunks = [...chunkArray(sortedData, itemsPerPage)];
    listingsData = listingsDataChunks[currentPage - 1];
  };

  useEffect(() => {
    if (mapData.length === 0) {
      (async () => {
        const data = await getMapData(router);
        data && setMapData(data);
      })();
    }
  });

  if (mapData.length > 0) {
    initData();
  }

  const scrollToRef = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return mapData ? (
    <div className={styles.mapListingWrapper} ref={scrollRef}>
      <div className={styles.mapListingLeft}>
        <div className={styles.mapListingHeader}>
          <Heading priority={1} fontStyle="h1">
            Communities in {stateAcronym}
          </Heading>
          <div className={styles.infoTextBlock}>
            {resultText}
            <div className={styles.mapToggle}>
              <ButtonStandard
                icon={icon}
                iconPosition="left"
                fillVariant="outline"
                onClick={toggleMobileMapVisible}
              >
                {mobileMapVisible ? 'List' : 'Map'}
              </ButtonStandard>
            </div>
          </div>
        </div>
        <div
          className={classNames(styles.mapGridList, {
            [styles.hidden]: mobileMapVisible
          })}
        >
          <MapListingGrid mapData={[...listingsData]} />
        </div>
        <div
          className={classNames(styles.mapListPagination, {
            [styles.hidden]: mobileMapVisible
          })}
        >
          {numPages !== 0 && mapData.length > itemsPerPage && (
            <Pagination
              boundaryCount={0}
              defaultPage={currentPage}
              numPages={numPages}
              onPageChanged={(pageNum) => {
                changePage(pageNum);
              }}
              siblingCount={1}
              theme="defaultTheme"
            />
          )}
        </div>
      </div>

      <div
        className={classNames(styles.mapListingRight, {
          [styles.hidden]: !mobileMapVisible
        })}
      >
        <div className={styles.mapWidget}>
          <Map
            zoomControl={true}
            fullscreenControl={true}
            streetViewControl={true}
            mapTypeControl={true}
            hasPopupCard={true}
            mapData={[...mapData]}
            maxZoom={17}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MapListingContainer;
