import { gql } from '@apollo/client';

export const relatedUrlLinksFragment = gql`
  fragment relatedUrlLinks on UrlLinks {
    __typename
    label
    urlLink
  }
`;

export const newsRelatedLinkFragment = gql`
  fragment newsRelatedLink on PageNationalNewsRelatedLinksItem {
    ...relatedUrlLinks
    ...hyperlink
  }
`;
