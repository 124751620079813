import React, { useEffect, useState } from 'react';

const FIRST_PAGE_NUMBER = 1;

/**
 * Custom hook for pagination logic
 * Taken from: https://github.com/mui/material-ui/blob/v5.10.4/packages/mui-material/src/usePagination/usePagination.js
 */
const usePagination = (opts: UsePaginationOptions = {}) => {
  const {
    boundaryCount = 1,
    count = 1,
    // currentPage = opts.defaultPage || 1,
    disabled = false,
    hideNextButton = false,
    hidePrevButton = false,
    onChange = () => {
      console.log('onChange');
    },
    showFirstButton = false,
    showLastButton = false,
    siblingCount = 1,
    defaultPage = 1
  } = opts;
  const [currentPage, setCurrentPage] = useState(defaultPage);

  // listen for a change in defaultPage value and set that as currentPage
  // handles case where the query params are delayed in being updated, ie. initial load
  useEffect(() => {
    if (currentPage !== defaultPage) {
      setCurrentPage(defaultPage);
    }
  }, [defaultPage]);

  const handleClick = (event: React.MouseEvent, value?: number) => {
    if (value !== undefined) {
      const newValue =
        value === 0 ? count : Math.max(value % (count + FIRST_PAGE_NUMBER), 1);

      setCurrentPage(newValue);
      onChange?.(event, newValue);
    }
  };

  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const startPages = range(1, Math.min(boundaryCount, count));
  const endPages = range(
    Math.max(count - boundaryCount + 1, boundaryCount + 1),
    count
  );

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      currentPage - siblingCount,
      // Lower boundary when page is high
      count - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      currentPage + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : count - 1
  );

  // Basic list of items to render
  // e.g. itemList = ['first', 'previous', 1, 'ellipsis', 4, 5, 6, 'ellipsis', 10, 'next', 'last']
  const itemList = [
    ...(showFirstButton ? ['first'] : []),
    ...(hidePrevButton ? [] : ['previous']),
    ...startPages,

    // Start ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsStart > boundaryCount + 2
      ? ['start-ellipsis']
      : boundaryCount + 1 < count - boundaryCount
        ? [boundaryCount + 1]
        : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsEnd < count - boundaryCount - 1
      ? ['end-ellipsis']
      : count - boundaryCount > boundaryCount
        ? [count - boundaryCount]
        : []),

    ...endPages,
    ...(hideNextButton ? [] : ['next']),
    ...(showLastButton ? ['last'] : [])
  ] as ItemType[];

  // Map the button type to its page number
  const getButtonPage = (type: SpecialItemType) => {
    switch (type) {
      case 'first':
        return 1;
      case 'previous':
        return currentPage - 1;
      case 'next':
        return currentPage + 1;
      case 'last':
        return count;
    }
  };

  // Convert the basic item list to PaginationItem props objects
  const items = itemList.map((item) => {
    return typeof item === 'number'
      ? {
          onClick: (event: React.MouseEvent) => {
            handleClick(event, item);
          },
          type: 'page',
          page: item,
          selected: item === currentPage,
          disabled,
          'aria-current': item === currentPage ? 'true' : undefined
        }
      : {
          onClick: (event: React.MouseEvent) => {
            handleClick(event, getButtonPage(item));
          },
          type: item,
          page: getButtonPage(item),
          selected: false,
          disabled:
            disabled ||
            (item.indexOf('ellipsis') === -1 &&
              (item === 'next' || item === 'previous' || item === 'last'
                ? currentPage >= count
                : currentPage <= 1))
        };
  });

  return {
    items,
    currentPage
  };
};

interface UsePaginationOptions {
  boundaryCount?: number;
  count?: number;
  defaultPage?: number;
  // currentPage?: number;
  disabled?: boolean;
  hideNextButton?: boolean;
  hidePrevButton?: boolean;
  onChange?: (event: React.MouseEvent, value?: number) => void;
  showFirstButton?: boolean;
  showLastButton?: boolean;
  siblingCount?: number;
}

export type SpecialItemType =
  | 'first'
  | 'previous'
  | 'ellipsis'
  | 'start-ellipsis'
  | 'end-ellipsis'
  | 'next'
  | 'last';
export type ItemType = SpecialItemType | number;

export default usePagination;
