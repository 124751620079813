import React from 'react';
import classNames from 'classnames';
import { getColorClasses } from '@src/utils/colorClasses';
import { Colors } from '@src/types/colors';
import styles from './EllipsisSVG.module.scss';

interface EllipsisSVG {
  color?: Colors;
}

const EllipsisSVG: React.FC<EllipsisSVG> = ({ color = 'slate' }) => {
  const fillColor = classNames(
    styles.ellipsisSvg,
    getColorClasses(color, styles)
  );

  return (
    <svg
      width="20"
      height="4"
      viewBox="0 0 20 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={fillColor} cx="2" cy="2" r="2" fillOpacity="0.24" />
      <circle className={fillColor} cx="10" cy="2" r="2" fillOpacity="0.24" />
      <circle className={fillColor} cx="18" cy="2" r="2" fillOpacity="0.24" />
    </svg>
  );
};

export default EllipsisSVG;
