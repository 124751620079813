import React from 'react';
import styles from './TileContainerBlock.module.scss';
import { TileContainerBlockFragment } from '@src/graphql/gql-types';
import Tile from './Tile';
import { COLOR_PRIMARY, COLOR_SLATE } from '@src/types/colors';
import SectionTitle from '@src/components/SectionTitle';
import CTAButton from '@src/components/Button/CTAButton';
import { StaticBlockData } from '@src/types/staticBlock';
import { ContentBlocksData } from '@src/types/contentBlocks';

type TileContainerBlock = TileContainerBlockFragment & {
  staticBlockData?: StaticBlockData;
  sharedBuildData?: ContentBlocksData;
};

const TileContainerBlock: React.FC<TileContainerBlock> = ({
  heading,
  description,
  tilesCollection,
  displayOptionText,
  primaryCta,
  secondaryCta
}) => {
  const items = tilesCollection?.items;

  return (
    <>
      {(heading || description) && (
        <SectionTitle heading={heading || ''} description={description || ''} />
      )}

      {items?.length && (
        <div className={styles.tileContainerBlock}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {items.map((tile: any, index: number) => (
            <Tile
              key={`${tile?.heading}_${index}`}
              {...tile}
              option={displayOptionText ? index + 1 : undefined}
            />
          ))}
        </div>
      )}

      {(primaryCta || secondaryCta) && (
        <div className={styles.ctaBlock}>
          {primaryCta && (
            <CTAButton
              cta={primaryCta}
              color={COLOR_PRIMARY}
              label={primaryCta?.label || ''}
            />
          )}
          {secondaryCta && (
            <CTAButton
              cta={secondaryCta}
              color={COLOR_SLATE}
              fillVariant="outline"
              label={secondaryCta?.label || ''}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TileContainerBlock;
