import { gql } from '@apollo/client';

export const pageCommunityDetailFragment = gql`
  fragment pageCommunityDetail on PageCommunityDetail {
    title
    suburb
    slug
    sys {
      ...sys
    }
    state {
      ...stateTerritory
    }
  }
`;
