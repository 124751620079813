import { gql } from '@apollo/client';

export const sectionTitleBlockFragment = gql`
  fragment sectionTitleBlock on SectionTitleBlock {
    __typename
    sys {
      ...sys
    }
    heading
    bodyContent {
      ...sectionTitleBlockRT
    }
  }

  fragment sectionTitleBlockRT on SectionTitleBlockBodyContent {
    json
    links {
      ...sectionTitleBlockRTLinks
    }
  }

  fragment sectionTitleBlockRTLinks on SectionTitleBlockBodyContentLinks {
    entries {
      block {
        sys {
          ...sys
        }
      }
      inline {
        sys {
          ...sys
        }
        ...mediaAsset
      }
      hyperlink {
        ...hyperlink
        ...mediaAsset
      }
    }
  }
`;
