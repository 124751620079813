import { gql, TypedDocumentNode } from '@apollo/client';
import {
  contentfulMetadataFragment,
  pageCommunityDetailMetadataFragment,
  pageCommunityEventHyperlinkFragment,
  pageCommunityEventMetadataFragment,
  sysFragment
} from '@src/graphql/fragments';

export const pageCommunityEventPathsQuery = gql`
  query pageCommunityEventPaths($eventDate: DateTime) {
    pageCommunityEventCollection(
      where: { slug_not_in: ["/", "sitemap"], eventDate_gte: $eventDate }
      limit: 1000
    ) {
      items {
        ...pageCommunityEventHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityDetailMetadataFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;

/**
 * Get the page hyperlink URL/path for a specific page ID
 * This query uses $id as `pageCommunityEvent` slugs are not unique for this content-type
 * only gets events where the date is greater than or equal to the date query variable
 */
export const pageCommunityEventPathQuery = gql`
  query pageCommunityEventPath(
    $id: String
    $nextJsParamsHashId: String
    $preview: Boolean
    $eventDate: DateTime
  ) {
    pagePath: pageCommunityEventCollection(
      where: {
        AND: [
          { eventDate_gte: $eventDate }
          {
            OR: [
              { sys: { id: $id } }
              { nextJsParamsHashId: $nextJsParamsHashId }
            ]
          }
        ]
      }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageCommunityEventHyperlink
      }
    }
  }
  ${sysFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityDetailMetadataFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;
