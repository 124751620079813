import React from 'react';
import { Container } from '@src/elements/Grid';
import { ProductPromotionBlockFragment } from '@src/graphql/gql-types';
import Heading from '@src/elements/Heading';
import ProductPromotionCard from './ProductPromotionCard';
import styles from './ProductPromotionBlock.module.scss';
import { COLOR_BLUE, COLOR_ORANGE, COLOR_YELLOW } from '@src/types/colors';
import { useRouter } from 'next/router';

type ProductPromotionBlock = ProductPromotionBlockFragment;

const ProductPromotionBlock: React.FC<ProductPromotionBlock> = ({
  heading,
  description,
  cardsCollection
}) => {
  const items = cardsCollection?.items;
  const nationalCardColors = [COLOR_YELLOW, COLOR_ORANGE, COLOR_BLUE];
  const router = useRouter();
  const national = !router.asPath.includes('/community/');
  return (
    <div className={styles.productPromotionBlock}>
      <Container containerWidth="fluid">
        <div className={styles.productPromotionBlockBody}>
          <div className={styles.productPromotionBlockContent}>
            <Heading priority={2} fontStyle="h2">
              {heading}
            </Heading>
            {description && <p className={styles.description}>{description}</p>}
          </div>
          <div className={styles.cardContainer}>
            {items &&
              items.map((card: any, index: number) => (
                <ProductPromotionCard
                  key={card.heading}
                  {...card}
                  optionNumber={index + 1}
                  color={national && nationalCardColors[index]}
                />
              ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProductPromotionBlock;
