import { gql, TypedDocumentNode } from '@apollo/client';
import {
  sysFragment,
  pageCommunityDetailMetadataFragment,
  pageCommunityDetailHyperlinkFragment,
  stateTerritoryFragment,
  contentfulMetadataFragment,
  heroLargeFragment,
  mediaAssetFragment,
  mediaImageFragment,
  mediaVideoFragment,
  alternatingGalleryBlockFragment,
  alternatingTextBlockFragment,
  CTAFragment,
  galleryFragment,
  hyperlinkFragment,
  matterportBlockFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityEventHyperlinkFragment,
  pageCommunityNewsMetadataFragment,
  pageCommunityEventMetadataFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityPropertyDetailMetadataFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityStandardMetadataFragment,
  pageNationalNewsHyperlinkFragment,
  pageNationalNewsMetadataFragment,
  pageNationalStandardHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  productPromotionBlockFragment,
  sectionTitleBlockFragment,
  contentBlocksCollectionCommunityDetailFragment,
  discoverBlockFragment,
  genericTextBlockFragment,
  videoBlockFragment,
  twoColumnBlockFragment
} from '@src/graphql/fragments';

export const pageCommunityDetailQuery = gql`
  query pageCommunityDetail($slug: String, $id: String, $preview: Boolean) {
    page: pageCommunityDetailCollection(
      where: { slug: $slug, sys: { id: $id } }
      preview: $preview
      limit: 1
    ) {
      items {
        sys {
          publishedAt
          ...sys
        }
        title
        slug
        introText
        state {
          ...stateTerritory
        }
        latLon {
          lat
          lon
        }
        seoTitle
        seoDescription
        noIndex
        noFollow
        hero {
          ...heroLarge
        }
        displayTourBookingButton
        introText
        displayGlobalCtaPanel
        isLiveChatEnabled
        discoverBlock {
          ...discoverBlock
        }
        disclaimer {
          ...genericTextBlock
        }
      }
    }

    pagePath: pageCommunityDetailCollection(
      where: { slug: $slug, sys: { id: $id } }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageCommunityDetailHyperlink
      }
    }
  }

  ${sysFragment}
  ${stateTerritoryFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${contentfulMetadataFragment}

  ${mediaAssetFragment}
  ${mediaImageFragment}
  ${mediaVideoFragment}

  ${genericTextBlockFragment}
  ${discoverBlockFragment}
  ${heroLargeFragment}
  ${galleryFragment}

  ${hyperlinkFragment}
  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;

export const mapCommunityDetail = gql`
  query mapCommunityDetail($stateSlug: String, $preview: Boolean) {
    stateCollection(preview: $preview, limit: 8) {
      items {
        slug
        __typename
      }
      __typename
    }
    mapItems: pageCommunityDetailCollection(
      where: { state: { slug: $stateSlug } }
      preview: $preview
      limit: 50
    ) {
      items {
        linkedFrom {
          propertiesForSale: pageCommunityPropertyDetailCollection(limit: 100) {
            items {
              sys {
                id
              }
              title
              propertyData {
                ... on ProductCatalog {
                  propertyType
                }
                ... on Product {
                  propertyType
                }
              }
            }
          }
        }
        mapAssets: hero {
          mediaItem {
            ... on MediaImage {
              cloudinarySource
              __typename
            }
            ... on MediaVideo {
              coverImage
              __typename
            }
            __typename
          }
          __typename
        }
        ...pageCommunityDetailHyperlink
        state {
          slug
        }
        latLon {
          lat
          lon
          __typename
        }
        __typename
      }
      __typename
    }
  }
  ${sysFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${contentfulMetadataFragment}
` as TypedDocumentNode;

export const pageCommunityDetailContentBlocksQuery = gql`
  query pageCommunityDetailContentBlocks($id: String, $preview: Boolean) {
    pageContentBlocks: pageCommunityDetailCollection(
      where: { sys: { id: $id } }
      preview: $preview
      limit: 1
    ) {
      items {
        ...contentBlocksPart1CollectionCommunityDetail
      }
    }
  }

  ${sysFragment}
  ${contentBlocksCollectionCommunityDetailFragment}

  ${hyperlinkFragment}
  ${contentfulMetadataFragment}

  ${galleryFragment}
  ${CTAFragment}

  ${mediaAssetFragment}
  ${mediaImageFragment}
  ${mediaVideoFragment}

  ${alternatingGalleryBlockFragment}
  ${alternatingTextBlockFragment}
  ${matterportBlockFragment}
  ${productPromotionBlockFragment}
  ${sectionTitleBlockFragment}
  ${videoBlockFragment}
  ${twoColumnBlockFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;
