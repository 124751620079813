import { gql } from '@apollo/client';

export const matterportBlockFragment = gql`
  fragment matterportBlock on Matterport3DVideoBlock {
    __typename
    sys {
      ...sys
    }
    heading
    description
    sourceUrl
  }
`;
