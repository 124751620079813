import React, { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useClickOutside = (
  ref: React.MutableRefObject<HTMLElement | null>,
  callback: (event: React.MouseEvent) => void
): void => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside: React.MouseEventHandler = (event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside as any);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside as any);
    };
  }, [ref, callback]);
};
