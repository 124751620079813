import { gql, TypedDocumentNode } from '@apollo/client';
import { contactPeopleFragment } from '@src/components/ContactCardList/ContactCardList.fragment';
import {
  sysFragment,
  pageCommunityPropertyDetailMetadataFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityDetailMetadataFragment,
  contentfulMetadataFragment,
  hyperlinkFragment,
  pageCommunityDetailHyperlinkFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityNewsMetadataFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityStandardMetadataFragment,
  pageNationalNewsHyperlinkFragment,
  pageNationalNewsMetadataFragment,
  pageNationalStandardHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  galleryFragment,
  galleryFloorPlansFragment,
  mediaAssetFragment,
  matterportBlockFragment,
  pageCommunityEventHyperlinkFragment,
  pageCommunityEventMetadataFragment
} from '@src/graphql/fragments';

/**
 * This query uses $id as `pageCommunityPropertyDetail` slugs are not unique for this content-type
 * Example WHERE OR statement
 *  - WHERE { OR: [{ slug: $slug }, { sys: { id: $id } }] }
 */
export const pageCommunityPropertyDetailQuery = gql`
  query pageCommunityPropertyDetail($id: String, $preview: Boolean) {
    page: pageCommunityPropertyDetailCollection(
      where: { sys: { id: $id } }
      preview: $preview
      limit: 1
    ) {
      items {
        sys {
          publishedAt
          ...sys
        }

        title
        slug
        community {
          isLiveChatEnabled
        }

        gallery {
          ...gallery
        }
        galleryFloorPlans {
          ...galleryFloorPlans
        }

        bodyContent {
          ...pageCommunityPropertyDetailRT
        }

        contactPeopleCollection(limit: 3) {
          items {
            ...contactPeople
          }
        }

        minPrice
        maxPrice

        propertyData {
          ... on ProductCatalog {
            minPrice
            maxPrice
            isStudy
            noOfBaths
            noOfBeds
            noOfCars
            noOfStudies
            propertyType
          }
          ... on Product {
            price
            noOfBaths
            noOfBeds
            noOfCars
            noOfStudies
            propertyType
          }
        }

        statusBadge {
          bannerLabel
          descriptionTitle
          bodyContent {
            json
          }
          colour
        }

        disclaimerText {
          __typename
          bodyContent {
            json
            links {
              entries {
                hyperlink {
                  ...hyperlink
                }
              }
            }
          }
        }

        seoTitle
        seoDescription
        noIndex
        noFollow
      }
    }

    pagePath: pageCommunityPropertyDetailCollection(
      where: { sys: { id: $id } }
      limit: 1
      preview: $preview
    ) {
      items {
        ...pageCommunityPropertyDetailHyperlink
      }
    }
  }

  fragment pageCommunityPropertyDetailRT on PageCommunityPropertyDetailBodyContent {
    json
    links {
      ...pageCommunityPropertyDetailRTLinks
    }
  }

  fragment pageCommunityPropertyDetailRTLinks on PageCommunityPropertyDetailBodyContentLinks {
    entries {
      block {
        sys {
          ...sys
        }
        ...matterportBlock
      }
      inline {
        sys {
          ...sys
        }
        ...mediaAsset
      }
      hyperlink {
        ...hyperlink
        ...mediaAsset
      }
    }
  }

  ${sysFragment}
  ${contentfulMetadataFragment}

  ${hyperlinkFragment}
  ${mediaAssetFragment}
  ${galleryFragment}
  ${galleryFloorPlansFragment}
  ${contactPeopleFragment}
  ${matterportBlockFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}
  ${pageCommunityDetailMetadataFragment}
  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityPropertyDetailMetadataFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}
  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventHyperlinkFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
` as TypedDocumentNode;

export const pageCommunityPropertiesQuery = gql`
  query pageCommunityProperties(
    $communitySlug: String
    $preview: Boolean
    $skip: Int
    $limit: Int
  ) {
    community: pageCommunityDetailCollection(
      where: { slug: $communitySlug }
      preview: $preview
      limit: 1
    ) {
      items {
        villageComparisonDocument {
          cloudinarySource
        }
      }
    }
    product: pageCommunityPropertyDetailCollection(
      where: { community: { slug: $communitySlug }, productType: "product" }
      preview: $preview
      limit: $limit
      skip: $skip
      order: title_ASC
    ) {
      total
      items {
        title
        slug
        community {
          slug
          title
        }
        statusBadge {
          colour
          bannerLabel
        }
        gallery {
          ...gallery
        }
        productType
        propertyType
        minPrice
        maxPrice
        noOfBeds
        noOfCars
        noOfBaths
      }
    }
    productCatalog: pageCommunityPropertyDetailCollection(
      where: {
        community: { slug: $communitySlug }
        productType: "productCatalog"
      }
      preview: $preview
      limit: $limit
      skip: $skip
      order: title_ASC
    ) {
      total
      items {
        title
        slug
        community {
          slug
          title
        }
        statusBadge {
          colour
          bannerLabel
        }
        gallery {
          ...gallery
        }
        productType
        propertyType
        minPrice
        maxPrice
        noOfBeds
        noOfCars
        noOfBaths
      }
    }
  }
  ${galleryFragment}
  ${sysFragment}
` as TypedDocumentNode;
