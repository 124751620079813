import React from 'react';
import { Column, Container, Row } from '@src/elements/Grid';
import Heading from '@src/elements/Heading';
import { CtaFragment, GlobalSettingItemFragment } from '@src/graphql/gql-types';
import { COLOR_BLUE } from '@src/types/colors';
import styles from './GlobalCTAPanel.module.scss';
import RichTextRender from '@src/components/RichTextRender';
import { CONTENTFUL_PAGE_CONTENT_TYPES } from '@src/constants/contentful';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import { SVG_PEN, SVG_TELEPHONE } from '@src/types/svg';
import { ButtonStandard } from '@src/components/Button';
import { getArrayOfCTAs } from '@src/utils/helpers';
import { NationalEnquiryDynamicFormValues } from '@src/types/forms';
import CTAButton from '@src/components/Button/CTAButton';
import { CreateLeadForm } from '@src/components/Forms';
import useModalStore from '@src/store';

type GlobalCTAPanel = GlobalSettingItemFragment & {
  isCommunity?: boolean;
  dynamicFormValues?: NationalEnquiryDynamicFormValues;
  formDisclaimerRichText?: GlobalSettingItemFragment;
};

const GlobalCTAPanel: React.FC<GlobalCTAPanel> = ({
  value,
  richText,
  callToActionCollection,
  isCommunity = false,
  dynamicFormValues,
  formDisclaimerRichText
}) => {
  const { openModal } = useModalStore();
  const ctasArray = getArrayOfCTAs(
    callToActionCollection?.items as CtaFragment[]
  );

  // Renders an enquiry form in a modal
  const handleEnquireButtonClick = () => {
    const modalData = {
      heading: 'Enquire',
      content: (
        <CreateLeadForm
          disclaimerRichText={formDisclaimerRichText}
          formType={isCommunity ? 'community_enquiry' : 'national_enquiry'}
          nationalEnquiryDynamicFormValues={
            isCommunity
              ? {}
              : (dynamicFormValues as NationalEnquiryDynamicFormValues)
          }
        />
      )
    };

    openModal(modalData);
  };

  const renderCTAButton = (cta: CtaFragment, index: number) => {
    return cta.modalId ? (
      <ButtonStandard
        key={`${cta.label}_${index}`}
        icon={SVG_PEN}
        iconPosition="left"
        color={COLOR_BLUE}
        colorVariant={'dark'}
        aria-label={cta.label || ''}
        onClick={handleEnquireButtonClick}
      >
        {cta.label}
      </ButtonStandard>
    ) : (
      <CTAButton
        key={index}
        cta={cta}
        label={cta.label || ''}
        icon={SVG_TELEPHONE}
        color={COLOR_BLUE}
        colorVariant={'dark'}
      />
    );
  };

  return (
    <div className={styles.panelContainer}>
      <Container>
        <Row>
          <Column xs={4} md={3} xl={4}>
            <Heading priority={2} fontStyle="h1-hero">
              {value}
            </Heading>
          </Column>
          <Column xs={4} md={5} xl={8}>
            {richText && (
              <RichTextRender
                bodyContent={richText}
                pageType={CONTENTFUL_PAGE_CONTENT_TYPES.NATIONAL_STANDARD} // Forcing this type at the moment, but it might not be needed/or applied in this instance
                renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
              />
            )}

            <div className={styles.ctasContainer}>
              {ctasArray?.map(renderCTAButton)}
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
};

export default GlobalCTAPanel;
