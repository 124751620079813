import { gql } from '@apollo/client';

export const salesBannerBlockFragment = gql`
  fragment salesBannerBlock on SalesBannerBlock {
    __typename
    sys {
      ...sys
    }
    heading
    bodyContent {
      ...salesBannerBlockRT
    }
    callToActionCollection(limit: 2) {
      __typename
      items {
        ...cta
      }
    }
    disclaimerText {
      ...salesBannerBlockDisclaimerTextRT
    }
  }

  fragment salesBannerBlockRT on SalesBannerBlockBodyContent {
    json
    links {
      ...salesBannerBlockRTLinks
    }
  }

  fragment salesBannerBlockRTLinks on SalesBannerBlockBodyContentLinks {
    entries {
      block {
        sys {
          ...sys
        }
      }
      inline {
        sys {
          ...sys
        }
        ...mediaAsset
      }
      hyperlink {
        ...hyperlink
      }
    }
  }

  fragment salesBannerBlockDisclaimerTextRT on SalesBannerBlockDisclaimerText {
    json
    links {
      ...salesBannerBlockDisclaimerTextRTLinks
    }
  }

  fragment salesBannerBlockDisclaimerTextRTLinks on SalesBannerBlockDisclaimerTextLinks {
    entries {
      block {
        sys {
          ...sys
        }
      }
      inline {
        sys {
          ...sys
        }
        ...mediaAsset
      }
      hyperlink {
        ...hyperlink
      }
    }
  }
`;
