import { useClickOutside } from '@src/hooks/useClickOutside';
import React, { useRef } from 'react';
import styles from './Dropdown.module.scss';

interface Dropdown {
  options: { key: string; label: string; id: string }[];
  onItemSelected: (id: string) => void;
  onClose: () => void;
}

const Dropdown: React.FC<Dropdown> = ({ options, onItemSelected }) => {
  return (
    <select
      className={styles.dropdown}
      onChange={(event) => {
        onItemSelected(event.target.value);
      }}
    >
      {options.map(({ key, label }) => (
        <option aria-label={label} key={key} value={key}>
          {label}
        </option>
      ))}
    </select>
  );
};

// TODO - this is a wip version that is more aligned with designs, but introduces accessibility and mobile UX challenges.
// We have agreed to start with a native select box version (see above), keeping the below code here in case we do need to swap
// to a more customised dropdown
export const DropdownV2: React.FC<Dropdown> = ({
  options,
  onItemSelected,
  onClose
}) => {
  const ref = useRef(null);
  useClickOutside(ref, (e) => {
    e.stopPropagation();
    onClose();
  });
  return (
    <div ref={ref} className={styles.dropdownV2}>
      <ul>
        {options.map(({ key, label, id }) => (
          <li
            key={key}
            onClick={() => {
              onItemSelected(id);
            }}
          >
            <div aria-label={label}>{label}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
