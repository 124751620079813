import { Column, Container, Row } from '@src/elements/Grid';
import { Colors } from '@src/types/colors';
import {
  DesktopColumnSpans,
  MobileColumnSpans,
  TabletColumnSpans
} from '@src/types/grid';
import { getColorClasses } from '@src/utils/colorClasses';
import classNames from 'classnames';
import React from 'react';
import styles from './SectionContainer.module.scss';

interface SectionContainer extends React.HTMLProps<HTMLElement> {
  // see src/elements/Grid/Column - we can copy more options here if we need even more flexibility
  columnOpts?: {
    xs?: MobileColumnSpans;
    sm?: MobileColumnSpans;
    md?: TabletColumnSpans;
    lg?: TabletColumnSpans;
    xl?: DesktopColumnSpans;
    xxl?: DesktopColumnSpans;
  };

  backgroundColor?: Colors;
  hideDefaultVerticalPadding?: boolean;
  renderContainer?: boolean;
}

const SectionContainer: React.FC<SectionContainer> = ({
  children,
  columnOpts = {},
  backgroundColor,
  hideDefaultVerticalPadding = true,
  renderContainer = true
}) => {
  const className = classNames(
    getColorClasses(backgroundColor as Colors, styles),
    {
      [styles.sectionContainerWithPadding]: !hideDefaultVerticalPadding
    }
  );
  return renderContainer ? (
    <section className={className}>
      <Container>
        <Row>
          <Column {...columnOpts}>{children}</Column>
        </Row>
      </Container>
    </section>
  ) : (
    <section className={className}>{children}</section>
  );
};

export default SectionContainer;
