import React from 'react';
import { Container } from '@src/elements/Grid';
import Heading from '@src/elements/Heading';
import Icon from '@src/elements/Icon';
import Visibility from '@src/elements/Visibility';
import { AlternatingTextBlockFragment } from '@src/graphql/gql-types';
import { SVG_PLAY } from '@src/types/svg';
import classNames from 'classnames';
import MediaImage from '@src/components/MediaImage';
import styles from './AlternatingTextBlock.module.scss';
import RichTextRender from '@src/components/RichTextRender';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import { ContentfulPageTypes } from '@src/types/contentful';
import { COLOR_PRIMARY } from '@src/types/colors';
import VideoModal from '@src/components/VideoModal';
import { replaceStringWithDictionary } from '@src/utils/strings';
import { ContentBlocksData } from '@src/types/contentBlocks';
import CTAButton from '@src/components/Button/CTAButton';

type AlternatingTextBlock = AlternatingTextBlockFragment & {
  pageType: ContentfulPageTypes;
  sharedBuildData?: ContentBlocksData;
};

const AlternatingTextBlock: React.FC<AlternatingTextBlock> = ({
  heading,
  bodyContent,
  layout,
  mediaItem,
  primaryCta,
  secondaryCta,
  pageType,
  sharedBuildData
}) => {
  const right = layout === 'text-right';
  const mediaImage = mediaItem?.__typename === 'MediaImage' && mediaItem;
  const mediaVideo = mediaItem?.__typename === 'MediaVideo' && mediaItem;
  const [videoIsOpen, setIsOpen] = React.useState(false);
  const replacementDictionary = sharedBuildData?.replacementDictionary;
  const headingText = replacementDictionary
    ? replaceStringWithDictionary(heading, replacementDictionary)
    : heading;

  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = 'auto';
  };

  const MobileViewportLayout = () => {
    return (
      <div>
        {/* Horizontal triangle for small devices */}
        <div className={styles.alternatingTextBlock}>
          <div
            className={classNames(styles.backgroundMediaWrapper, {
              [styles.backgroundMediaWrapperRight]: right
            })}
          >
            {mediaImage && <MediaImage {...mediaImage} />}
            {mediaVideo && <MediaImage {...mediaVideo} />}
          </div>
          <div
            className={classNames(styles.background, {
              [styles.backgroundRight]: right
            })}
          >
            <svg className={styles.backgroundTriangle} viewBox="0 0 769 278">
              <polygon points="769 0, 0 278, 769 278" />
            </svg>
          </div>
          {mediaVideo && (
            <div className={styles.buttonContainer}>
              <button className={styles.playButton} onClick={openModal}>
                <div className={styles.playIcon}>
                  <Icon icon={SVG_PLAY}></Icon>
                </div>
              </button>
            </div>
          )}
        </div>
        <div className={styles.mobileContentWrapper}>
          <Heading priority={2} fontStyle="h2" className={styles.heading}>
            {headingText}
          </Heading>
          {bodyContent && (
            <RichTextRender
              bodyContent={bodyContent}
              pageType={pageType}
              renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
              replacementDictionary={replacementDictionary}
              slateLinkStyle
            />
          )}
          {(primaryCta || secondaryCta) && (
            <div className={styles.ctaWrapper}>
              {primaryCta && (
                <CTAButton
                  cta={primaryCta}
                  label={primaryCta.label ?? ''}
                  color={COLOR_PRIMARY}
                />
              )}
              {secondaryCta && (
                <CTAButton
                  cta={secondaryCta}
                  label={secondaryCta.label ?? ''}
                  color={COLOR_PRIMARY}
                  fillVariant="outline"
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const LargeViewportLayout = () => {
    return (
      <div className={styles.alternatingTextBlock}>
        <div
          className={classNames(styles.backgroundMediaWrapper, {
            [styles.backgroundMediaWrapperRight]: right
          })}
        >
          {mediaImage && <MediaImage {...mediaImage} />}
          {mediaVideo && <MediaImage {...mediaVideo} />}
        </div>
        <div
          className={classNames(styles.background, {
            [styles.backgroundRight]: right
          })}
        >
          <div className={styles.backgroundBlock} />
          {/* Vertical triangle for large devices */}
          <svg className={styles.backgroundTriangle} viewBox="0 0 301 677">
            <polygon points="0 0, 0 677, 301 677" />
          </svg>
        </div>
        <div
          className={classNames(styles.alternatingTextBlockLarge, {
            [styles.alternatingTextBlockLargeRight]: right
          })}
        >
          <div
            className={classNames(styles.contentWrapper, {
              [styles.contentWrapperRight]: right
            })}
          >
            <Heading priority={2} fontStyle="h2" className={styles.heading}>
              {headingText}
            </Heading>
            {bodyContent && (
              <RichTextRender
                bodyContent={bodyContent}
                pageType={pageType}
                renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                replacementDictionary={replacementDictionary}
                slateLinkStyle
              />
            )}
            {(primaryCta || secondaryCta) && (
              <div className={styles.ctaWrapper}>
                {primaryCta && (
                  <CTAButton
                    cta={primaryCta}
                    label={primaryCta.label ?? ''}
                    color={COLOR_PRIMARY}
                  />
                )}
                {secondaryCta && (
                  <CTAButton
                    cta={secondaryCta}
                    label={secondaryCta.label ?? ''}
                    color={COLOR_PRIMARY}
                    fillVariant="outline"
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {mediaVideo && (
          <div
            className={classNames(styles.buttonContainer, {
              [styles.buttonContainerLeft]: right
            })}
          >
            <button
              className={styles.playButton}
              onClick={openModal}
              aria-label={`Play ${heading} Video`}
            >
              <div className={styles.playIcon}>
                <Icon icon={SVG_PLAY}></Icon>
              </div>
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Visibility xs md={false}>
        <MobileViewportLayout />
      </Visibility>
      <Visibility xs={false} md xl={false}>
        <LargeViewportLayout />
      </Visibility>
      <Visibility xs={false} xl>
        <Container>
          <LargeViewportLayout />
        </Container>
      </Visibility>
      {mediaVideo && (
        <VideoModal
          mediaVideo={mediaVideo}
          videoIsOpen={videoIsOpen}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default AlternatingTextBlock;
