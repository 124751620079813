import { Colors } from '@src/types/colors';
import React from 'react';
import AccordionBlock from '@src/components/AccordionBlock';
import AlternatingGalleryBlock from '@src/components/AlternatingGalleryBlock';
import AlternatingTextBlock from '@src/components/AlternatingTextBlock';
import GenericText from '@src/components/GenericText';
import MatterportBlock from '@src/components/MatterportBlock';
import ProductPromotionBlock from '@src/components/ProductPromotionBlock';
import SectionContainer from '@src/components/SectionContainer';
import SectionTitle from '@src/components/SectionTitle';
import StaticBlock from '@src/components/StaticBlock';
import TileContainerBlock from '@src/components/TileContainerBlock';
import TwoColumnBlock from '@src/components/TwoColumnBlock';
import VideoBlock from '@src/components/VideoBlock';
import { ContentfulPageTypes } from '@src/types/contentful';
import { StaticBlockData } from '@src/types/staticBlock';
import { ContentBlocksData, ContentBlocksItem } from '@src/types/contentBlocks';
import ContactUsCommunity from '@src/components/FormContainer/ContactUsCommunity';
import SalesBannerBlock from '@src/components/SalesBannerBlock';
import MediaImageBlock from '@src/components/MediaImageBlock';

type Block = Exclude<ContentBlocksItem, undefined | null | number>;
interface ContentBlocks {
  items: ContentBlocksItem[];
  pageType: ContentfulPageTypes;

  // query response data that was used to build the page, since potentially some of the blocks might need
  // that information
  staticBlockData?: StaticBlockData;
  sharedBuildData?: ContentBlocksData;
}

type BlockTypeName = Exclude<Block['__typename'], undefined | null | number>;

interface SectionOpts {
  backgroundColor?: Colors;
  hideDefaultVerticalPadding?: boolean; // defaults to true, since currently internal components tend to have their own vertical padding
  renderContainer?: boolean; // means that block component is wrapped in a Container component. Defaults to true, should rarely need to pass false
}

interface ComponentsDictionaryItem<T> {
  Component: React.FC<T>;

  // default - true. Should always aim to keep it true, but providing an escape hatch for when you really need
  // to have a content block without a section / with the section defined internally
  wrapInSection?: boolean;
  sectionOpts?: SectionOpts;
}

// component dictionary for lookup based on a block type name.
// We should add here any component that needs to be rendered as a content block.
// the type definitions ensure one can only add properties whose name is an existing block __typename
const ComponentsDictionary: Partial<
  Record<BlockTypeName, ComponentsDictionaryItem<any>>
> = {
  AccordionBlock: {
    Component: AccordionBlock
  },
  AlternatingGalleryBlock: {
    Component: AlternatingGalleryBlock
  },
  AlternatingTextBlock: {
    Component: AlternatingTextBlock,
    sectionOpts: {
      renderContainer: false
    }
  },
  CommunityContactUsBlock: {
    Component: ContactUsCommunity
  },
  GenericTextBlock: {
    Component: GenericText
  },
  Matterport3DVideoBlock: {
    Component: MatterportBlock
  },
  ProductPromotionBlock: {
    Component: ProductPromotionBlock,
    sectionOpts: {
      renderContainer: false
    }
  },
  SectionTitleBlock: {
    Component: SectionTitle
  },
  StaticBlock: {
    Component: StaticBlock,
    sectionOpts: {
      renderContainer: false
    }
  },
  TileContainerBlock: {
    Component: TileContainerBlock
  },
  TwoColumnBlock: {
    Component: TwoColumnBlock,
    sectionOpts: {
      renderContainer: false
    }
  },
  VideoBlock: {
    Component: VideoBlock,
    sectionOpts: {
      renderContainer: false
    }
  },
  MediaImage: {
    // In ContentBlocks we will display the MediaImage content-type with the MediaImageBlock component
    Component: MediaImageBlock,
    wrapInSection: false,
    sectionOpts: {
      renderContainer: false
    }
  },
  SalesBannerBlock: {
    Component: SalesBannerBlock,
    sectionOpts: {
      renderContainer: false
    }
  }
  // TODO - add more components
};

/**
 * a reusable component for rendering a page's content blocks. It uses an internal dictionary for looking up the component to render based on each
 * block's typename
 */

const ContentBlocks: React.FC<ContentBlocks> = ({
  items,
  pageType,
  staticBlockData = {},
  sharedBuildData = {}
}) => {
  const data = items;
  return (
    <>
      {data.map((block, index) => {
        const blockData = block as any;

        const blockDataWithFormData = {
          communities: staticBlockData.communities,
          formDisclaimerRichText:
            staticBlockData.nationalContactForm?.disclaimerRichText,
          ...blockData
        };
        const typeName = block?.__typename;
        const componentDictionaryItem =
          ComponentsDictionary[typeName as keyof typeof ComponentsDictionary];
        const {
          Component,
          wrapInSection = true,
          sectionOpts = {}
        } = componentDictionaryItem || {};
        if (Component) {
          return wrapInSection ? (
            <SectionContainer
              key={`${blockData.sys?.id}_${index}`}
              {...sectionOpts}
            >
              <Component
                {...(['SalesBannerBlock', 'StaticBlock'].includes(
                  typeName ?? ''
                )
                  ? blockDataWithFormData
                  : blockData)}
                pageType={pageType}
                staticBlockData={staticBlockData}
                sharedBuildData={sharedBuildData}
              />
            </SectionContainer>
          ) : (
            <Component
              key={`${blockData.sys?.id}_${index}`}
              {...blockData}
              pageType={pageType}
              staticBlockData={staticBlockData}
              sharedBuildData={sharedBuildData}
            />
          );
        }
        return <></>;
      })}
    </>
  );
};

export default ContentBlocks;
