import { MapListingCard } from '@src/components/Card';
import { getPageUrlPath } from '@src/utils/url';
import React, { useEffect, useState } from 'react';
import { MapListingCardType } from '@src/types/map';
import { pascalToDisplay } from '@src/utils/strings';

const MapListingGrid = ({ mapData }: { mapData: Record<string, any> }) => {
  const [cards, setCards] = useState<MapListingCardType[]>([]);

  const addCards = (data: Record<string, any>) => {
    const newCards: MapListingCardType[] = [];

    data.forEach((item: Record<string, any>) => {
      const path = getPageUrlPath(item);
      // Loop through properties for this community and retrieve their types
      let propertyTypes = item.linkedFrom?.propertiesForSale?.items
        .map((item: any) => {
          return pascalToDisplay(item.propertyData?.propertyType);
        })
        .filter(Boolean); // remove empty items

      // Dedupe property types and filter out empty items before joining
      propertyTypes = [...new Set(propertyTypes.filter(Boolean))]?.join(', ');

      const card = {
        communitySlug: path ? path : '',
        forSaleSlug: path ? path + 'for-sale' : '',
        state: item.state?.slug ? item.state?.slug.toUpperCase() : '',
        title: item.title,
        image: item.mapAssets?.mediaItem,
        theme: item.theme,
        suburb: item.suburb,
        propertyTypes
      };

      newCards.push(card);
    });

    setCards([...newCards]);
  };

  useEffect(() => {
    if (mapData.length > 0) {
      addCards(mapData);
    }
  }, [mapData]);

  const getCards = () => {
    return cards.map((card, index) => (
      <MapListingCard
        key={index}
        title={card.title}
        image={card.image}
        forSaleSlug={card.forSaleSlug}
        communitySlug={card.communitySlug}
        state={card.state}
        suburb={card.suburb}
        propertyTypes={card.propertyTypes}
        theme={card.theme}
      />
    ));
  };

  return mapData ? <>{getCards()}</> : <></>;
};

export default MapListingGrid;
