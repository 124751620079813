import { PageCommunityDetailFragment } from '@src/graphql/gql-types';
import { NationalEnquiryDynamicFormValues } from '@src/types/forms';

/**
 * Takes query response and tranforms to object suitable for dynamic form fields in NationalEnquiryForm
 * @param enquiryFormValues
 * @returns object representation of states with list of community title and salesforceProjectId
 */
export const getEnquiryFormValues = (
  enquiryFormValues: PageCommunityDetailFragment[]
) => {
  const formValues: NationalEnquiryDynamicFormValues = {};

  enquiryFormValues?.forEach((item) => {
    const key = item?.state?.slug?.toLocaleUpperCase();

    if (item?.state?.slug && key) {
      const title = item.title as string;

      if (formValues[key]) {
        formValues[key].push({
          title,
          sys: item.sys
        });
      } else {
        formValues[key] = [
          {
            title,
            sys: item.sys
          }
        ];
      }
    }
  });

  return formValues;
};

/**
 *
 * @param reCaptchaResponse
 * @returns response from validateReCaptcha API
 */
export const validateRecaptchaResponse = async (
  reCaptchaResponse: string | null | undefined
) => {
  if (!reCaptchaResponse) {
    return false;
  }

  try {
    const response = await fetch('/api/validateReCaptcha', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        isTestRun: process.env.NEXT_PUBLIC_LOCAL_DEV,
        reCaptchaResponse
      })
    });

    const data = await response.json();

    return data;
  } catch (err) {
    console.log('validateRecaptchaResponse error', err);
  }
};
