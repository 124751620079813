import { Column, Container, Row } from '@src/elements/Grid';
import { PageCommunityDetailFragment } from '@src/graphql/gql-types';
import React from 'react';
import styles from './StateCommunityList.module.scss';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import classNames from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import Heading from '@src/elements/Heading';
import ButtonIcon from '../Button/ButtonIcon';
import { COLOR_SLATE } from '@src/types/colors';
import { SVG_ARROW_RIGHT, SVG_CHEVRON_RIGHT } from '@src/types/svg';
import Icon from '@src/elements/Icon';
import Visibility from '@src/elements/Visibility';
import { ButtonLinkStandard } from '../ButtonLink';
import {
  groupCommunitiesByState,
  groupStatesByLabel,
  splitCommunityGroupsInColumns
} from '@src/utils/helpers';

interface StateCommunityList {
  communities: PageCommunityDetailFragment[];
}

const StateCommunityList: React.FC<StateCommunityList> = ({ communities }) => {
  const groupedByState = groupCommunitiesByState(communities) || {}; // for mobile/tablet
  const columns = splitCommunityGroupsInColumns(communities); // for desktop
  const stateSlugsByName = groupStatesByLabel(communities);
  const router = useRouter();

  return (
    <Container>
      <Visibility md xs sm lg={false} xl={false} xxl={false} xxxl={false}>
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          className={styles.accordion}
        >
          {Object.keys(groupedByState || {})?.map((state, stateIndex) => (
            <AccordionItem key={state}>
              <AccordionItemHeading>
                <AccordionItemButton
                  className={classNames(styles.accordionButton, {
                    [styles.accordionFirstButton]: stateIndex === 0
                  })}
                >
                  <Heading priority={3} fontStyle="h3">
                    {state}
                  </Heading>
                  <ButtonIcon
                    size="sm"
                    fillVariant="outline"
                    icon={SVG_CHEVRON_RIGHT}
                    aria-label="Toggle accordion menu"
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className={styles.accordionItemPanel}>
                <ButtonLinkStandard
                  fillVariant="outline"
                  icon={SVG_ARROW_RIGHT}
                  iconPosition="right"
                  href={
                    (router.asPath + stateSlugsByName[state].slug) as string
                  }
                >
                  View on map
                </ButtonLinkStandard>
                <br />
                {groupedByState[state]?.map((community) => (
                  <NextLink
                    href={`/community/${community?.slug as string}`}
                    key={community?.title}
                    legacyBehavior
                  >
                    <div className={styles.communityListItem}>
                      <div>
                        <p>{community?.title}</p>
                        <p className={styles.accordionItemSubheading}>
                          {community?.suburb}
                        </p>
                      </div>
                      <Icon icon={SVG_CHEVRON_RIGHT} color={COLOR_SLATE} />
                    </div>
                  </NextLink>
                ))}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Visibility>
      <Visibility md={false} xs={false} sm={false} lg xl xxl xxxl>
        <Row>
          {columns.map((column, columnIndex) => (
            <Column key={columnIndex} lg={8} xl={6} xxl={6}>
              {Object.keys(column || {})?.map((state, stateIndex) => {
                return (
                  <div key={state}>
                    <div
                      className={classNames(styles.stateHeading, {
                        [styles.stateHeadingFirst]: stateIndex === 0
                      })}
                    >
                      <Heading priority={3} fontStyle="h3">
                        {state}
                      </Heading>
                      <ButtonLinkStandard
                        fillVariant="outline"
                        icon={SVG_ARROW_RIGHT}
                        iconPosition="right"
                        href={
                          (router.asPath +
                            stateSlugsByName[state].slug) as string
                        }
                      >
                        View on map
                      </ButtonLinkStandard>
                    </div>
                    <div>
                      {column[state]?.map((community) => (
                        <NextLink
                          href={`/community/${community?.slug as string}`}
                          key={community?.title}
                          legacyBehavior
                        >
                          <div
                            data-testid="stateCommunityListItem"
                            className={styles.communityListItem}
                          >
                            <div>
                              <p>{community?.title}</p>
                              <p className={styles.accordionItemSubheading}>
                                {community?.suburb}
                              </p>
                            </div>
                            <Icon
                              icon={SVG_CHEVRON_RIGHT}
                              color={COLOR_SLATE}
                            />
                          </div>
                        </NextLink>
                      ))}
                    </div>
                  </div>
                );
              })}
            </Column>
          ))}
        </Row>
      </Visibility>
    </Container>
  );
};

export default StateCommunityList;
