import { gql } from '@apollo/client';
import {
  sysFragment,
  navElFragment,
  pageCommunityDetailMetadataFragment,
  pageCommunityStandardHyperlinkFragment,
  pageCommunityStandardMetadataFragment,
  pageNationalStandardHyperlinkFragment,
  pageNationalStandardMetadataFragment,
  pageNationalNewsHyperlinkFragment,
  pageNationalNewsMetadataFragment,
  pageCommunityDetailHyperlinkFragment,
  pageCommunityNewsHyperlinkFragment,
  pageCommunityNewsMetadataFragment,
  navSubElFragment,
  navFragment,
  hyperlinkFragment,
  contentfulMetadataFragment,
  pageCommunityPropertyDetailHyperlinkFragment,
  pageCommunityPropertyDetailMetadataFragment,
  mediaAssetFragment,
  pageCommunityEventMetadataFragment,
  pageCommunityEventHyperlinkFragment
} from '@src/graphql/fragments';

// NOTE: community queries for some national data e.g. footerNav
export const communityBasePageQuery = gql`
  query communityBasePage(
    $id: String
    $community_id: String!
    $preview: Boolean
  ) {
    primaryNav: navigationCollection(
      preview: $preview
      where: { sys: { id: $id } }
      limit: 1
    ) {
      items {
        ...nav
      }
    }

    footerNav: navigationCollection(
      preview: $preview
      where: { codeId: "national-footer" }
      limit: 1
    ) {
      items {
        navigationElementsCollection(limit: 10) {
          items {
            ...navEl
          }
        }
      }
    }

    socialLinks: navigationCollection(
      preview: $preview
      where: { codeId: "social-links" }
      limit: 1
    ) {
      items {
        navigationElementsCollection(limit: 10) {
          items {
            ...navEl
          }
        }
      }
    }

    legalLinks: navigationCollection(
      preview: $preview
      where: { codeId: "legal-links" }
      limit: 1
    ) {
      items {
        navigationElementsCollection(limit: 10) {
          items {
            ...navEl
          }
        }
      }
    }

    lookupPagesForCommunity: pageCommunityDetailCollection(
      where: { sys: { id: $community_id } }
      limit: 1
      preview: $preview
    ) {
      items {
        linkedFrom {
          pageCommunityStandardCollection(limit: 50) {
            items {
              ...pageCommunityStandardHyperlink
            }
          }
        }
      }
    }

    communityDetail: pageCommunityDetail(id: $community_id) {
      state {
        stateterritoryLabel
        slug
      }
      title
      slug
      theme
    }
  }

  ${sysFragment}
  ${hyperlinkFragment}
  ${contentfulMetadataFragment}

  ${navFragment}
  ${navElFragment}
  ${navSubElFragment}

  ${mediaAssetFragment}

  ${pageNationalStandardHyperlinkFragment}
  ${pageNationalNewsHyperlinkFragment}

  ${pageCommunityStandardHyperlinkFragment}
  ${pageCommunityDetailHyperlinkFragment}

  ${pageNationalStandardMetadataFragment}
  ${pageNationalNewsMetadataFragment}

  ${pageCommunityStandardMetadataFragment}
  ${pageCommunityDetailMetadataFragment}

  ${pageCommunityNewsMetadataFragment}
  ${pageCommunityNewsHyperlinkFragment}
  ${pageCommunityEventMetadataFragment}
  ${pageCommunityEventHyperlinkFragment}

  ${pageCommunityPropertyDetailMetadataFragment}
  ${pageCommunityPropertyDetailHyperlinkFragment}
`;
