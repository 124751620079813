import {
  NewsListingCardNationalFragment,
  NewsListingCardCommunityFragment,
  EventListingCardCommunityFragment,
  PageNationalNewsListFilterQuery,
  PageCommunityNewsListFilterQuery,
  PageCommunityEventListFilterQuery,
  PageNationalNewsListFilterQueryVariables,
  PageCommunityNewsListFilterQueryVariables,
  PageCommunityEventListFilterQueryVariables,
  Maybe
} from '@src/graphql/gql-types';

export type ListingType = 'news' | 'events';

export type NewsQueryContext = 'national' | 'community';

export type NewsEventQueries =
  | PageNationalNewsListFilterQuery
  | PageCommunityNewsListFilterQuery
  | PageCommunityEventListFilterQuery;

export type NewsEventQueryVariables =
  | PageNationalNewsListFilterQueryVariables
  | PageCommunityNewsListFilterQueryVariables
  | PageCommunityEventListFilterQueryVariables;

export type CardFragments =
  | EventListingCardCommunityFragment
  | NewsListingCardCommunityFragment
  | NewsListingCardNationalFragment
  | NewsListingCardSharedFragment;

export type NewsListingCardSharedFragment = Maybe<{
  __typename: 'PageSharedNews';
  publishDate?: any | null | undefined;
  sourcePage?:
    | {
        __typename: 'PageNationalNews';
        title?: string | null | undefined;
        slug?: string | null | undefined;
        sys: { __typename?: 'Sys'; id: string };
        publishDate?: any | null | undefined;
        readingTime?: any | null | undefined;
        hero: any | null | undefined;
      }
    | null
    | undefined;
  parentPage?:
    | {
        __typename: 'PageCommunityDetail';
        title?: string | null | undefined;
        slug?: string | null | undefined;
        theme?: string | null | undefined;
        isLiveChatEnabled?: boolean | null | undefined;
        suburb?: string | null | undefined;
        sys: { __typename?: 'Sys'; id: string };
        navigation?:
          | {
              __typename?: 'Navigation';
              sys: { __typename?: 'Sys'; id: string };
            }
          | null
          | undefined;
        contentfulMetadata: {
          __typename?: 'ContentfulMetadata';
          tags: Array<
            | {
                __typename: 'ContentfulTag';
                id?: string | null | undefined;
                name?: string | null | undefined;
              }
            | null
            | undefined
          >;
        };
      }
    | null
    | undefined;
  sys: { __typename?: 'Sys'; id: string };
}>;

// function checks query result types and returns a type predicate
export const isCommunityEventsQueryResult = (
  data: Maybe<NewsEventQueries>
): data is PageCommunityEventListFilterQuery =>
  (data as PageCommunityEventListFilterQuery)?.eventList?.__typename !==
  undefined;

export const isCommunityNewsQueryResult = (
  data: Maybe<NewsEventQueries>
): data is PageCommunityNewsListFilterQuery =>
  (data as PageCommunityNewsListFilterQuery)?.newsList?.__typename !==
    undefined &&
  (data as PageCommunityNewsListFilterQuery)?.newsList?.__typename ===
    'PageCommunityNewsCollection';
