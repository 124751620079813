import { Column, Container, Row } from '@src/elements/Grid';
import Heading from '@src/elements/Heading';
import {
  CommunityBasePageQuery,
  NationalBasePageQuery,
  PageCommunityDetailFragment
} from '@src/graphql/gql-types';
import { splitCommunityGroupsInColumns } from '@src/utils/helpers';
import React from 'react';
import styles from './Sitemap.module.scss';
import { getLinkData } from '@src/utils/url';
import LinkWrapper from '../LinkWrapper';

interface Sitemap {
  communities?: PageCommunityDetailFragment[];
  primaryNav?:
    | NationalBasePageQuery['primaryNav']
    | CommunityBasePageQuery['primaryNav'];
}

const Sitemap: React.FC<Sitemap> = ({ communities, primaryNav }) => {
  const columns = splitCommunityGroupsInColumns(communities);
  const navItems =
    primaryNav?.items?.[0]?.navigationElementsCollection?.items || [];
  const navLinks = getLinkData(navItems) || [];

  return (
    <Container>
      <Row>
        <Column xs={4} sm={4} md={8} lg={8} xl={8} xxl={8}>
          <Heading priority={3} fontStyle="h3" className={styles.columnHeading}>
            Levande living
          </Heading>
          <Row>
            {columns.map((column, columnIndex) => (
              <Column
                key={columnIndex}
                xs={4}
                sm={2}
                md={4}
                lg={4}
                xl={6}
                xxl={6}
              >
                {Object.keys(column || {})?.map((state) => {
                  return (
                    <div key={state}>
                      <div>
                        <p className={styles.groupHeading}>{state}</p>
                      </div>
                      <ul className={styles.groupList}>
                        {column[state]?.map((community) => (
                          <li
                            key={community?.title}
                            className={styles.groupListItem}
                          >
                            <LinkWrapper
                              key={community?.title}
                              href={`/community/${community?.slug as string}`}
                              hoverUnderline
                            >
                              {community?.title}
                            </LinkWrapper>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </Column>
            ))}
          </Row>
        </Column>
        <Column xs={4} sm={4} md={8} lg={4} xl={4} xxl={4}>
          <Heading
            priority={3}
            fontStyle="h3"
            className={`${styles.columnHeading} ${styles.lastColumnHeading}`}
          >
            About Levande
          </Heading>
          <Row>
            <Column xs={4} sm={4} md={8} lg={8} xl={12} xxl={12}>
              {navLinks.map((item) => (
                <span key={item.title}>
                  <LinkWrapper
                    href={item.urlPath as string}
                    openNewWindow={!!item.openNewWindow}
                    hoverUnderline
                  >
                    <p className={styles.groupHeading}>{item.title}</p>
                  </LinkWrapper>
                  <ul className={styles.groupList}>
                    {item?.subNav?.map((subNavItem) => (
                      <li
                        key={subNavItem?.sys?.id}
                        className={styles.groupListItem}
                      >
                        <LinkWrapper
                          href={subNavItem.urlPath as string}
                          openNewWindow={!!subNavItem.openNewWindow}
                          hoverUnderline
                        >
                          {subNavItem.title}
                        </LinkWrapper>
                      </li>
                    ))}
                  </ul>
                </span>
              ))}
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  );
};

export default Sitemap;
