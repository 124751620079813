import React from 'react';
import styles from './AccordionBlock.module.scss';
import Heading from '@src/elements/Heading';
import { AccordionBlockFragment } from '@src/graphql/gql-types';
import { ContentfulPageTypes } from '@src/types/contentful';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import RichTextRender from '../RichTextRender';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import { ButtonIcon } from '../Button';

type AccordionBlock = AccordionBlockFragment & {
  pageType: ContentfulPageTypes;
};

const AccordionBlock: React.FC<AccordionBlock> = ({
  heading,
  bodyContent,
  accordionItemsCollection,
  pageType
}) => (
  <div className={styles.accordionBlock}>
    <Heading priority={2} fontStyle="h2">
      {heading}
    </Heading>
    {bodyContent && (
      <RichTextRender
        bodyContent={bodyContent}
        pageType={pageType}
        renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
      />
    )}
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      className={styles.accordion}
    >
      {accordionItemsCollection?.items.map((accordionItem, index) => (
        <AccordionItem key={index}>
          <AccordionItemHeading className={styles.itemHeading}>
            <AccordionItemButton
              aria-level={2}
              className={styles.itemButton}
              data-testid="accordionItem"
            >
              {accordionItem?.heading}
              <ButtonIcon
                size="xs"
                icon="ChevronDown"
                fillVariant="outline"
                isBorderless
                aria-label="Toggle accordion menu"
              />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel
            className={styles.itemPanel}
            data-testid="accordionItemPanel"
          >
            <div className={styles.itemContent}>
              {accordionItem?.bodyContent && (
                <RichTextRender
                  bodyContent={accordionItem?.bodyContent}
                  pageType={pageType}
                  renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                />
              )}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  </div>
);

export default AccordionBlock;
