import React from 'react';
import { StaticBlockFragment } from '@src/graphql/gql-types';
import { StaticBlockData } from '@src/types/staticBlock';
import { STATIC_BLOCK_COMPONENT_NAME } from '@src/constants/contentful';
import StateCommunityList from '@src/components/StateCommunityList';
import NewsEventsListingGrid from '../GridList/NewsEventsListingGrid';
import { MapListingContainer } from '@src/components/PageContainer';
import PropertySearch from '@src/components/PropertySearch';
import { ContentBlocksData } from '@src/types/contentBlocks';
import ContactUsNational from '../FormContainer/ContactUsNational';
import Sitemap from '@src/components/Sitemap';
import { ListingType } from '@src/types/newsAndEvents';

interface StaticBlock extends StaticBlockFragment {
  staticBlockData?: StaticBlockData;
  sharedBuildData?: ContentBlocksData;
  selectedTab?: ListingType;
}

const StaticBlock: React.FC<StaticBlock> = ({
  componentName,
  staticBlockData = {},
  sharedBuildData = {}
}) => {
  switch (componentName) {
    case STATIC_BLOCK_COMPONENT_NAME.COMMUNITY_NEWS_EVENTS_LIST:
      return <NewsEventsListingGrid queryType="community" />;

    case STATIC_BLOCK_COMPONENT_NAME.NATIONAL_NEWS_LIST:
      return <NewsEventsListingGrid queryType="national" />;

    case STATIC_BLOCK_COMPONENT_NAME.COMMUNITY_PROPERTY_SEARCH:
      return (
        <PropertySearch
          communityName={sharedBuildData.communityName}
          communitySlug={sharedBuildData.communitySlug}
        />
      );

    case STATIC_BLOCK_COMPONENT_NAME.FORM_NATIONAL_CONTACT_US:
      return (
        <ContactUsNational
          {...staticBlockData.nationalContactForm}
          communities={staticBlockData?.communities}
          sharedBuildData={sharedBuildData}
        />
      );

    case STATIC_BLOCK_COMPONENT_NAME.INTERACTIVE_MAP_STATE_COMMUNITIES: {
      return <MapListingContainer />;
    }
    case STATIC_BLOCK_COMPONENT_NAME.SITEMAP_LIST:
      return (
        <Sitemap
          communities={staticBlockData?.communities}
          primaryNav={staticBlockData?.primaryNav}
        />
      );

    case STATIC_BLOCK_COMPONENT_NAME.STATE_COMMUNITY_LIST:
      return staticBlockData?.communities ? (
        <StateCommunityList communities={staticBlockData?.communities} />
      ) : (
        <></>
      );

    default:
      return <></>;
  }
};

export default StaticBlock;
