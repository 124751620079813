import { AlternatingGalleryBlockFragment } from '@src/graphql/gql-types';
import React from 'react';
import RichTextRender from '@src/components/RichTextRender';
import styles from './AlternatingGalleryBlock.module.scss';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import { ContentfulPageTypes } from '@src/types/contentful';
import { Column, Row } from '@src/elements/Grid';
import Heading from '@src/elements/Heading';
import { COLOR_PRIMARY } from '@src/types/colors';
import Showcase from '@src/components/Showcase';
import { replaceStringWithDictionary } from '@src/utils/strings';
import { ContentBlocksData } from '@src/types/contentBlocks';
import CTAButton from '@src/components/Button/CTAButton';

type AlternatingGalleryBlock = AlternatingGalleryBlockFragment & {
  pageType: ContentfulPageTypes;
  sharedBuildData?: ContentBlocksData;
};

const AlternatingGalleryBlock: React.FC<AlternatingGalleryBlock> = ({
  heading,
  bodyContent,
  gallery,
  primaryCta,
  secondaryCta,
  pageType,
  sharedBuildData
}) => {
  const replacementDictionary = sharedBuildData?.replacementDictionary;
  const headingText = replacementDictionary
    ? replaceStringWithDictionary(heading, replacementDictionary)
    : heading;

  return (
    <>
      <div className={styles.alternatingGalleryBlock}>
        <Row>
          <Column xl={4} md={8} sm={4}>
            <div className={styles.heading}>
              <Heading priority={2} fontStyle="h2">
                {headingText}
              </Heading>
            </div>
            {bodyContent && (
              <div className={styles.richTextWrapper}>
                <RichTextRender
                  bodyContent={bodyContent}
                  pageType={pageType}
                  renderMode={RICHTEXT_RENDER_MODE.NO_BLOCK_EMBEDS}
                  replacementDictionary={replacementDictionary}
                />
              </div>
            )}

            {(primaryCta || secondaryCta) && (
              <div className={styles.ctaWrapper}>
                {primaryCta && (
                  <CTAButton
                    cta={primaryCta}
                    label={primaryCta.label ?? ''}
                    color={COLOR_PRIMARY}
                  />
                )}
                {secondaryCta && (
                  <CTAButton
                    cta={secondaryCta}
                    label={secondaryCta.label ?? ''}
                    color={COLOR_PRIMARY}
                    fillVariant="outline"
                  />
                )}
              </div>
            )}
          </Column>
          <Column xl={8} md={8} sm={4}>
            {gallery && (
              <Showcase
                heading={headingText || ''}
                gallery={gallery?.cloudinarySource}
              />
            )}
          </Column>
        </Row>
      </div>
    </>
  );
};

export default AlternatingGalleryBlock;
