import { gql } from '@apollo/client';

export const productPromotionBlockFragment = gql`
  fragment productPromotionBlock on ProductPromotionBlock {
    __typename
    sys {
      ...sys
    }
    heading
    description
    cardsCollection(limit: 3) {
      items {
        heading
        description
        cta {
          ...cta
        }
        image {
          ...mediaImage
        }
      }
    }
  }
`;
