import { gql } from '@apollo/client';

export const discoverBlockFragment = gql`
  fragment discoverBlock on DiscoverBlock {
    __typename
    sys {
      ...sys
    }
    heading
    description
    cardsCollection(limit: 3) {
      items {
        heading
        image {
          ...mediaImage
        }
        internalLink {
          ...hyperlink
        }
        urlLink
        assetLink {
          ...mediaAsset
        }
      }
    }
  }
`;
