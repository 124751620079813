import { GenericTextBlockFragment } from '@src/graphql/gql-types';
import { ContentfulPageTypes } from '@src/types/contentful';
import React from 'react';
import RichTextRender from '@src/components/RichTextRender';
import styles from './GenericText.module.scss';
import classNames from 'classnames';
import { RICHTEXT_RENDER_MODE } from '@src/types/richText';
import Content from '@src/elements/Content';
import { ContentBlocksData } from '@src/types/contentBlocks';
import { Container } from '@src/elements/Grid';

type GenericText = GenericTextBlockFragment & {
  pageType: ContentfulPageTypes;
  disclaimer?: boolean;
  sharedBuildData?: ContentBlocksData;
};

const GenericText: React.FC<GenericText> = ({
  bodyContent,
  pageType,
  disclaimer,
  sharedBuildData
}) => {
  const replacementDictionary = sharedBuildData?.replacementDictionary;
  return (
    <div
      className={classNames(styles.genericText, {
        [styles.disclaimer]: disclaimer
      })}
    >
      {bodyContent &&
        (disclaimer ? (
          <Container>
            <Content>
              <RichTextRender
                bodyContent={bodyContent}
                pageType={pageType}
                renderMode={RICHTEXT_RENDER_MODE.ALLOW_BLOCK_EMBEDS}
                replacementDictionary={replacementDictionary}
              />
            </Content>
          </Container>
        ) : (
          <Content>
            <RichTextRender
              bodyContent={bodyContent}
              pageType={pageType}
              renderMode={RICHTEXT_RENDER_MODE.ALLOW_BLOCK_EMBEDS}
              replacementDictionary={replacementDictionary}
            />
          </Content>
        ))}
    </div>
  );
};

export default GenericText;
