export const chunkArray = (array: any[], chunkSize: number) => {
  if (chunkSize <= 0) {
    throw new Error('invalid input');
  }
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
};

export const uniq = (array: string[]) => {
  const occurences: Record<string, string> = {};
  array.forEach((item) => {
    if (!occurences[item]) {
      occurences[item] = item;
    }
  });
  return Object.values(occurences);
};
