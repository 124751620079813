import { gql } from '@apollo/client';

export const tileContainerBlockFragment = gql`
  fragment tileContainerBlock on TileContainerBlock {
    __typename
    sys {
      ...sys
    }
    heading
    description
    tilesCollection(limit: 3) {
      items {
        heading
        description
        image {
          ...mediaImage
        }
        primaryCta {
          ...cta
        }
      }
    }
    displayOptionText
    primaryCta {
      ...cta
    }
    secondaryCta {
      ...cta
    }
  }
`;
