import { gql } from '@apollo/client';

export const staticBlockFragment = gql`
  fragment staticBlock on StaticBlock {
    __typename
    sys {
      ...sys
    }
    componentName
  }
`;
